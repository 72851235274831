.paragraph {
  // display: block;
  text-align: left;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.1rem;
  color: var(--white);
  padding: 15px;
}

.card {
  @extend .paragraph;
  position: relative;

  @media (max-width: 1440px) {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  @media (max-width: 1366px) {
    font-size: var(--fontSizeXS);
  }

  @media (max-width: 1366px) {
    font-size: var(--fontSizeXS);
  }

  @media (max-width: 1024px) {
    // font-size: 1.1rem;
    padding-top: 0px;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
    // padding-top: 0px;
  }
}

// .cardDate {
//   text-align: left;
//   @extend .paragraph;
//   height: 22px;
//   display: flex;
//   flex-direction: row;
//   justify-content: left;
//   line-height: 1.5rem;
//   align-content: center;
//   gap: 5px;
//   align-items: center;
//   padding: 0px;
//   padding-left: 10px;
//   font-weight: 300;
//   font-size: 1.5rem;
//   font-family: var(--font-headers);
//   color: var(--colorMainGrey);
// }

.article {
  @extend .paragraph;
  display: block;
  width: calc(100% - 40px);
  padding: 30px;
  // padding-bottom: 10px;
  // margin: 20px;

  position: absolute;
  font-size: 1.8rem;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--colorArticlesE);
  border: 1px solid var(--colorArticles);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 500;
  backdrop-filter: blur(8px);

  @media (max-width: 1366px) {
    font-size: var(--fontSizeS);
  }

  @media (max-width: 1024px) {
    font-size: var(--fontSizeXS);
    padding: 20px;
  }

  // text-transform: uppercase;
}

.details {
  @extend .paragraph;
  padding-left: 50px;
  padding-right: 345px;
  // border: 1px solid red;

  @media (max-width: 480px) {
    margin: 0;
    padding: 0px;
    font-size: 1.4rem;
  }
}
