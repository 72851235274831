.menu {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-47%, -53%);
}

.ulist {
  list-style: none;
}

.navLink {
  text-decoration: none;
}

.active {
  text-decoration: none;
}

.borderMenuWrapper {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  width: 250px;
  height: 280px;
}
@media (max-width: 1600px) {
  .borderMenuWrapper {
    transform: scale(0.9);
    top: calc(50% - 140px);
    left: 5px;
  }
}
@media (max-width: 1366px) {
  .borderMenuWrapper {
    transform: scale(0.85);
    left: -5px;
  }
}
@media (max-width: 1024px) {
  .borderMenuWrapper {
    transform: scale(0.73);
    left: -24px;
  }
}
@media (max-width: 480px) {
  .borderMenuWrapper {
    display: none;
  }
}

.topLine {
  position: absolute;
  width: 90%;
  top: -8px;
  left: 0;
  border-top: 1px solid var(--colorArticlesF);
}
@media (max-width: 480px) {
  .topLine {
    display: none;
  }
}

.topLineCorner {
  position: absolute;
  width: 13px;
  top: -13px;
  left: 89.3%;
  border-top: 1px solid var(--colorArticlesF);
  transform: rotate(-45deg);
}
@media (max-width: 480px) {
  .topLineCorner {
    display: none;
  }
}

.rightLineCornerA {
  position: absolute;
  top: 3px;
  height: 13px;
  right: -13px;
  border-right: 1px solid var(--colorArticlesF);
  transform: rotate(45deg);
}
@media (max-width: 480px) {
  .rightLineCornerA {
    display: none;
  }
}

.rightLineCornerB {
  position: absolute;
  height: 13px;
  right: -13px;
  border-right: 1px solid var(--colorArticlesF);
  transform: rotate(-45deg);
  top: 94.3%;
}
@media (max-width: 480px) {
  .rightLineCornerB {
    display: none;
  }
}

.rightLine {
  position: absolute;
  top: 5%;
  bottom: 5%;
  right: -8px;
  border-right: 1px solid var(--colorArticlesF);
}
@media (max-width: 480px) {
  .rightLine {
    display: none;
  }
}

.underLine {
  position: absolute;
  width: 45%;
  bottom: -8px;
  right: 20px;
  border-bottom: 1px solid var(--colorArticlesF);
}
@media (max-width: 480px) {
  .underLine {
    display: none;
  }
}

.underLineCornerB {
  position: absolute;
  width: 5%;
  bottom: -13px;
  right: 10px;
  border-bottom: 1px solid var(--colorArticlesF);
  transform: rotate(45deg);
}
@media (max-width: 480px) {
  .underLineCornerB {
    display: none;
  }
}

.underLineCornerA {
  position: absolute;
  width: 5%;
  bottom: -13px;
  right: calc(18px + 45%);
  border-bottom: 1px solid var(--colorArticlesF);
  transform: rotate(-45deg);
}
@media (max-width: 480px) {
  .underLineCornerA {
    display: none;
  }
}

.backgroundMenu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 280px;
  -webkit-clip-path: polygon(0% 0%, 92% 0, 100% 5%, 100% 95%, 92% 100%, 48% 100%, 40% 95%, 8% 95%, 0 90%);
          clip-path: polygon(0% 0%, 92% 0, 100% 5%, 100% 95%, 92% 100%, 48% 100%, 40% 95%, 8% 95%, 0 90%);
  overflow: hidden;
}
@media (max-width: 480px) {
  .backgroundMenu {
    display: none;
  }
}

.backgroundMenu::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(24, 0, 0, 0) 50%, var(--colorArticlesB) 50%);
  background-size: 100% 4px;
  z-index: 2;
  pointer-events: none;
  opacity: 0.4;
}
@media (max-width: 480px) {
  .backgroundMenu::before {
    display: none;
  }
}

.scanline {
  width: 100%;
  height: 25%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, var(--colorArticlesA) 10%, rgba(49, 0, 0, 0.1) 100%);
  opacity: 0.55;
  position: absolute;
  bottom: 100%;
  transform: translateY(0);
  will-change: transform;
  animation: scanline 8s linear infinite;
}
@media (max-width: 480px) {
  .scanline {
    display: none;
  }
}

.cornerTopRight {
  position: absolute;
  top: 1px;
  right: -2px;
  width: 21px;
  outline: 1px solid var(--colorArticles);
  transform-origin: top left;
  transform: rotate(35deg);
}
@media (max-width: 480px) {
  .cornerTopRight {
    display: none;
  }
}

.cornerBottomRight {
  position: absolute;
  bottom: 1px;
  right: -2px;
  width: 21px;
  outline: 1px solid var(--colorArticles);
  transform-origin: top left;
  transform: rotate(-35deg);
}
@media (max-width: 480px) {
  .cornerBottomRight {
    display: none;
  }
}

.cornerMiddle {
  position: absolute;
  bottom: 1px;
  right: 44.6%;
  width: 21px;
  outline: 1px solid var(--colorArticles);
  transform-origin: bottom left;
  transform: rotate(215deg);
}
@media (max-width: 480px) {
  .cornerMiddle {
    display: none;
  }
}

.cornerBottomLeft {
  position: absolute;
  bottom: 27px;
  left: 1px;
  width: 21px;
  outline: 1px solid var(--colorArticles);
  transform-origin: bottom left;
  transform: rotate(35deg);
}
@media (max-width: 480px) {
  .cornerBottomLeft {
    display: none;
  }
}

.bottomLine {
  position: absolute;
  width: 31.5%;
  bottom: 14px;
  left: 20px;
  outline: 1px solid var(--colorArticles);
}
@media (max-width: 480px) {
  .bottomLine {
    display: none;
  }
}

.borderMenu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 280px;
  border: 2px solid var(--colorArticles);
  -webkit-clip-path: polygon(0% 0%, 92% 0, 100% 5%, 100% 95%, 92% 100%, 48% 100%, 40% 95%, 8% 95%, 0 90%);
          clip-path: polygon(0% 0%, 92% 0, 100% 5%, 100% 95%, 92% 100%, 48% 100%, 40% 95%, 8% 95%, 0 90%);
  background-color: var(--colorArticlesB);
}

.titleMenu {
  display: block;
  position: absolute;
  right: 18px;
  bottom: 2px;
  font-size: 1.2rem;
  font-weight: 300;
  font-family: var(--font-pixel);
  color: var(--colorArticlesC);
}
@media (max-width: 480px) {
  .titleMenu {
    display: none;
  }
}/*# sourceMappingURL=Menu.module.css.map */