.search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 50%;
  gap: 20px;
  height: 4vh;
  padding-left: 0;
}
@media (max-width: 1680px) {
  .search {
    width: 55%;
  }
}
@media (max-width: 1600px) {
  .search {
    width: 50%;
  }
}
@media (max-width: 1440px) {
  .search {
    width: 55%;
  }
}

.inputWrap {
  width: 45%;
}

.titleWrap {
  width: 55%;
}

.title {
  text-align: left;
  display: block;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: var(--bold);
  color: var(--white);
  font-family: var(--font-headers);
  text-transform: uppercase;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.title b {
  opacity: 0.7;
  letter-spacing: -2px;
}
@media (max-width: 1366px) {
  .title b {
    letter-spacing: -1px;
  }
}
@media (max-width: 1600px) {
  .title {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}
@media (max-width: 1366px) {
  .title {
    font-size: 1.4rem;
  }
}
@media (max-width: 1280px) {
  .title {
    letter-spacing: 1px;
  }
}
@media (max-width: 1152px) {
  .title {
    font-size: 1.2rem;
  }
}
@media (max-width: 1024px) {
  .title {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .title {
    font-size: 1.5rem;
  }
}/*# sourceMappingURL=Filter.module.css.map */