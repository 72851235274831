@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Libre+Barcode+39&family=Montserrat:wght@300;600&family=Press+Start+2P&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus {
  outline: 0;
}

button:focus {
  outline: 0;
}

textarea:focus {
  outline: 0;
}

:root {
  --cardWidth: 440px;
  --cardHeight: 360px;
  --btnloginWidth: 150px;
  --buttonWidth: 210px;
  --buttonWidthSec: 120px;
  --buttonHeight: 40px;
  --buttonHeightSec: 30px;
  --inputHeight: 40px;
  --inputWidth: 240px;
  --colorRadio: rebeccapurple;
  --colorNews: #2bd8ff;
  --colorNewsDark: #0060df;
  --colorPeople: #ff2bd8;
  --colorPeopleDark: #ce132c;
  --colorArticles: #2b87ff;
  --colorArticlesA: #2b64ff25;
  --colorArticlesB: #2b6eff4f;
  --colorArticlesC: #00a2ffa9;
  --colorArticlesD: #57bcff9c;
  --colorArticlesE: #0a45887e;
  --colorArticlesEB: #0b44859c;
  --colorArticlesF: #0061cfd8;
  --colorArticlesG: #4fbeffce;
  --colorArticlesDark: #0026ff;
  --colorArticlesDarkB: #2e2f3f;
  --colorArticlesDarkBA: #2e2f3fd0;
  --color-light-grey: #8d8d8d;
  --colorMainGrey: #373737;
  --colorGrey100: hsl(0, 0%, 75%);
  --colorGrey200: hsl(0, 0%, 60%);
  --colorGrey300: hsl(0, 0%, 50%);
  --colorGrey400: hsl(0, 0%, 30%);
  --colorBgkL: rgba(98, 97, 89, 1);
  --colorBgkD: rgba(59, 58, 52, 1);
  --colorBgkVD: rgb(45, 44, 37);
  --black: rgb(80, 80, 80);
  --blackA: rgba(80, 80, 80, 0.6);
  --blackB: rgb(32, 32, 32);
  --white: rgba(255, 255, 255, 0.959);
  --colorSocial: rgb(5, 5, 5);
  --fontSizeXXS: 1rem;
  --fontSizeXS: 1.2rem;
  --fontSizeS: 1.6rem;
  --fontSizeM: 2.1rem;
  --fontSizeL: 2.4rem;
  --fontSizeXL: 4rem;
  --light: 300;
  --bold: 600;
  --font-main: "Montserrat", sans-serif;
  --font-headers: "Audiowide", cursive;
  --font-pixel: "Press Start 2P", cursive;
}
@media (max-width: 1600px) {
  :root {
    --cardWidth: 380px;
    --cardHeight: 308px;
    --inputHeight: 28px;
    --inputWidth: 210px;
    --buttonWidth: 180px;
    --buttonWidthSec: 95px;
    --buttonHeight: 30px;
    --buttonHeightSec: 25px;
  }
}
@media (max-width: 1366px) {
  :root {
    --cardWidth: 360px;
    --cardHeight: 292px;
  }
}
@media (max-width: 1024px) {
  :root {
    --cardWidth: 330px;
    --cardHeight: 268px;
  }
}
@media (max-width: 480px) {
  :root {
    --cardWidth: 300px;
    --cardHeight: 243px;
    --buttonWidthSec: 155px;
    --buttonHeightSec: 28px;
  }
}

.logo1 {
  fill: white;
  transition: 0.2s ease-in;
}

html {
  font-size: 62.5%;
}

body {
  padding-left: 176px;
  font-family: var(--font-main);
  font-size: 1.6rem;
  overflow: hidden;
  width: 100%;
  background-color: black;
}
@media (max-width: 1366px) {
  body {
    padding-left: 156px;
  }
}
@media (max-width: 1024px) {
  body {
    padding-left: 126px;
  }
}
@media (max-width: 480px) {
  body {
    padding: 0;
    height: 100vh;
    width: 100vw;
  }
}

.wrapCont {
  display: block;
}
@media (max-width: 480px) {
  .wrapCont {
    pointer-events: none;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }
}

strong {
  font-weight: 600;
}

h1 {
  font-size: 3.6rem;
}

h2 {
  font-size: 2.8rem;
}

.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centeredB {
  margin: 3rem auto;
  flex-direction: column;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.routeLink {
  display: block;
  text-decoration: none;
}

.page {
  position: relative;
  width: calc(100vw - 176px);
  height: 100vh;
}
@media (max-width: 1366px) {
  .page {
    width: calc(100vw - 156px);
  }
}
@media (max-width: 1024px) {
  .page {
    width: calc(100vw - 126px);
  }
}
@media (max-width: 480px) {
  .page {
    width: 100vw;
  }
}

.backgroundBody, .backgroundBack {
  position: absolute;
  width: calc(100vw - 176px);
  height: 100%;
  background: linear-gradient(90deg, var(--colorArticles) 0%, var(--colorBgkL) 2%, var(--colorBgkD) 20%, var(--colorBgkD) 89%, var(--colorBgkL) 96%, var(--colorArticles) 100%);
}
@media (max-width: 1366px) {
  .backgroundBody, .backgroundBack {
    width: calc(100vw - 156px);
  }
}
@media (max-width: 1024px) {
  .backgroundBody, .backgroundBack {
    width: calc(100vw - 126px);
  }
}
@media (max-width: 480px) {
  .backgroundBody, .backgroundBack {
    width: 100vw;
    background: linear-gradient(90deg, var(--colorArticles) 0%, var(--colorBgkL) 10%, var(--colorBgkD) 20%, var(--colorBgkD) 80%, var(--colorBgkL) 90%, var(--colorArticles) 100%);
  }
}

.backgroundBack {
  z-index: -2;
  background: linear-gradient(90deg, var(--colorArticles) 0%, var(--colorBgkL) 2%, var(--colorBgkD) 20%, var(--colorBgkD) 100%);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--colorArticlesE);
  mix-blend-mode: overlay;
}

.twitter-follow-button {
  font-family: var(--font-headers);
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--white);
  text-decoration: none;
}

.twitter-follow-button:hover {
  color: var(--black);
}

#logo1 {
  fill: red;
}

.wrapperTwit {
  padding-top: 3px;
  height: 28px;
  width: 28px;
}

.headingTwit {
  display: block;
  font-size: 1.2rem;
  transition: 0.2s ease-in;
}
@media (max-width: 1600px) {
  .headingTwit {
    display: none;
  }
}

.contactSlide {
  animation-iteration-count: 1;
  animation-duration: 0.6s;
  animation-name: slideKont;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}

.colorIn {
  animation-iteration-count: 1;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
  animation-name: KolorIn;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}

.contactHide {
  animation-iteration-count: 1;
  animation-duration: 0.6s;
  animation-name: hideKont;
  animation-fill-mode: both;
}

.colorOut {
  animation-iteration-count: 1;
  animation-duration: 0.6s;
  animation-name: KolorOut;
  animation-fill-mode: both;
}

input[type=radio] {
  display: inline-block;
  position: relative;
  width: 22px;
  height: 12px;
  margin: 10px 10px 10px 0;
  opacity: 0.5;
  transition: 120ms transform ease-in-out;
  cursor: pointer;
  pointer-events: auto;
  place-content: center;
}
@media (max-width: 480px) {
  input[type=radio] {
    margin-top: 25px;
    width: 12px;
    height: 12px;
  }
}

input[type=radio]:checked {
  opacity: 1;
}

#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4,
#s5:checked ~ #slide5,
#s6:checked ~ #slide6,
#s7:checked ~ #slide7 {
  transform: translate3d(0%, 0, 0px);
}
@media (max-width: 480px) {
  #s1:checked ~ #slide1,
  #s2:checked ~ #slide2,
  #s3:checked ~ #slide3,
  #s4:checked ~ #slide4,
  #s5:checked ~ #slide5,
  #s6:checked ~ #slide6,
  #s7:checked ~ #slide7 {
    transform: translate3d(0, 0%, 0px);
  }
}

#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide5,
#s5:checked ~ #slide6,
#s6:checked ~ #slide7,
#s7:checked ~ #slide1 {
  transform: translate3d(30%, 0, -200px);
  opacity: 0.8;
  filter: blur(1px);
}
@media (max-width: 480px) {
  #s1:checked ~ #slide2,
  #s2:checked ~ #slide3,
  #s3:checked ~ #slide4,
  #s4:checked ~ #slide5,
  #s5:checked ~ #slide6,
  #s6:checked ~ #slide7,
  #s7:checked ~ #slide1 {
    transform: translate3d(0, 30%, -200px);
  }
}

#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide5,
#s4:checked ~ #slide6,
#s5:checked ~ #slide7,
#s6:checked ~ #slide1,
#s7:checked ~ #slide2 {
  transform: translate3d(60%, 0, -600px);
  filter: blur(3px);
  opacity: 0.6;
}
@media (max-width: 480px) {
  #s1:checked ~ #slide3,
  #s2:checked ~ #slide4,
  #s3:checked ~ #slide5,
  #s4:checked ~ #slide6,
  #s5:checked ~ #slide7,
  #s6:checked ~ #slide1,
  #s7:checked ~ #slide2 {
    transform: translate3d(0, 60%, -600px);
  }
}

#s1:checked ~ #slide4,
#s2:checked ~ #slide5,
#s3:checked ~ #slide6,
#s4:checked ~ #slide7,
#s5:checked ~ #slide1,
#s6:checked ~ #slide2,
#s7:checked ~ #slide3 {
  transform: translate3d(100%, 0, -1200px);
  filter: blur(5px);
  opacity: 0.4;
}
@media (max-width: 480px) {
  #s1:checked ~ #slide4,
  #s2:checked ~ #slide5,
  #s3:checked ~ #slide6,
  #s4:checked ~ #slide7,
  #s5:checked ~ #slide1,
  #s6:checked ~ #slide2,
  #s7:checked ~ #slide3 {
    transform: translate3d(0, 100%, -1200px);
  }
}

#s1:checked ~ #slide5,
#s2:checked ~ #slide6,
#s3:checked ~ #slide7,
#s4:checked ~ #slide1,
#s5:checked ~ #slide2,
#s6:checked ~ #slide3,
#s7:checked ~ #slide4 {
  transform: translate3d(-100%, 0, -1200px);
  filter: blur(5px);
  opacity: 0.4;
}
@media (max-width: 480px) {
  #s1:checked ~ #slide5,
  #s2:checked ~ #slide6,
  #s3:checked ~ #slide7,
  #s4:checked ~ #slide1,
  #s5:checked ~ #slide2,
  #s6:checked ~ #slide3,
  #s7:checked ~ #slide4 {
    transform: translate3d(0, -100%, -1200px);
  }
}

#s1:checked ~ #slide6,
#s2:checked ~ #slide7,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2,
#s5:checked ~ #slide3,
#s6:checked ~ #slide4,
#s7:checked ~ #slide5 {
  transform: translate3d(-60%, 0, -600px);
  filter: blur(3px);
  opacity: 0.6;
}
@media (max-width: 480px) {
  #s1:checked ~ #slide6,
  #s2:checked ~ #slide7,
  #s3:checked ~ #slide1,
  #s4:checked ~ #slide2,
  #s5:checked ~ #slide3,
  #s6:checked ~ #slide4,
  #s7:checked ~ #slide5 {
    transform: translate3d(0, -60%, -600px);
  }
}

#s1:checked ~ #slide7,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3,
#s5:checked ~ #slide4,
#s6:checked ~ #slide5,
#s7:checked ~ #slide6 {
  transform: translate3d(-30%, 0, -200px);
  filter: blur(1px);
  opacity: 0.8;
}
@media (max-width: 480px) {
  #s1:checked ~ #slide7,
  #s2:checked ~ #slide1,
  #s3:checked ~ #slide2,
  #s4:checked ~ #slide3,
  #s5:checked ~ #slide4,
  #s6:checked ~ #slide5,
  #s7:checked ~ #slide6 {
    transform: translate3d(0, -30%, -200px);
  }
}

.overflowWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(90% - 80px);
  height: calc(70% - 80px);
  overflow: hidden;
  flex-direction: row;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 1366px) {
  .overflowWrapper {
    left: 51%;
    width: calc(89% - 80px);
  }
}
@media (max-width: 480px) {
  .overflowWrapper {
    height: calc(85% - 24px);
    width: calc(100% - 24px);
    left: 50%;
  }
}/*# sourceMappingURL=main.css.map */