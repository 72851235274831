@import '../../../scss/animations';

.sidebar {
  position: fixed;
  width: 170px;
  height: calc(100vh - 12px);
  left: 6px;
  top: 6px;
  // z-index: 1;
  background-image: url('../../../assets/icons/palsterB.svg');
  background-repeat: repeat;
  background-position: 32.2%, 32.2%;
  background-size: 32.2%;
  mask-image: url('../../../assets/icons/maska.svg');
  mask-repeat: repeat;
  mask-position: 32.2%, 32.2%;
  mask-size: 32.2%;
  clip-path: polygon(0 1.6%, 10% 0, 100% 0, 100% 100%, 10% 100%, 0 98.4%);
  // border: 1px solid red;
  @media (max-width: 1366px) {
    width: 150px;
  }

  @media (max-width: 1024px) {
    width: 120px;
  }

  @media (max-width: 480px) {
    display: none;
  }
}

.background {
  position: fixed;
  width: 176px;
  height: 100vh;
  left: 0;
  top: 0;
  // z-index: -1;
  // background: rgb(0, 0, 0);
  background: #061a41;

  @media (max-width: 1366px) {
    width: 156px;
  }

  @media (max-width: 1024px) {
    width: 126px;
  }

  @media (max-width: 480px) {
    display: none;
  }
}

.wrapper {
  position: fixed;
  width: 170px;
  height: 100vh;
  left: 0;
  top: 0;

  @media (max-width: 1366px) {
    width: 150px;
  }

  @media (max-width: 1024px) {
    width: 120px;
  }

  @media (max-width: 480px) {
    display: none;
  }
}

.glowWrapper {
  position: absolute;
  top: 49%;
  transform: translate(0, -50%);
  left: 0;
  width: 170px;
  height: 250px;
  // z-index: 1;
  // border: 1px solid red;

  @media (max-width: 1600px) {
    height: 225px;
    // top: 51%;
  }

  @media (max-width: 1366px) {
    height: 210px;
    width: 150px;
    top: 50%;
  }

  @media (max-width: 1024px) {
    height: 190px;
    width: 126px;
  }

  @media (max-width: 480px) {
    display: none;
  }
}

.glow {
  position: absolute;
  top: 0%;
  transform: translateY(0);
  left: 5px;
  height: 65px;
  width: 165px;
  background: radial-gradient(
    closest-side,
    rgb(227, 248, 255) 30%,
    var(--colorArticlesD) 55%,
    #00b7ff85 75%,
    rgba(0, 0, 0, 0) 95%
  );
  transition: top 0.4s ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-name: glowMovment;
  // border: 1px solid red;

  @media (max-width: 1600px) {
    height: 63px;
  }

  @media (max-width: 1366px) {
    width: 160px;
    height: 52px;
  }

  @media (max-width: 1024px) {
    width: 120px;
  }

  @media (max-width: 480px) {
    display: none;
  }
}

.animeA {
  top: 0%;
}
.animeB {
  top: 25%;
}
.animeC {
  top: 50%;
}
.animeD {
  top: 75%;
}

// .helper {
//   position: absolute;
//   top: 50%;
//   transform: translate(0, -50%);
//   left: 10px;
//   width: 250px;
//   height: 280px;
//   border: 1px solid blueviolet;
// }
.ulistM {
  position: absolute;
}
