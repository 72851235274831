@keyframes btnBlink {
  0% {
    color: var(--colorArticles);
    border-right: 2px solid var(--colorArticles);
  }
  5% {
    border-right: 2px solid var(--white);
  }
  15% {
    color: var(--white);
  }
  25% {
    border-right: 2px solid var(--white);
    color: var(--white);
  }
  30% {
    border-right: 2px solid var(--colorArticles);
  }
  35% {
    color: var(--colorArticles);
    border-bottom: 2px solid var(--colorArticles);
  }
  40% {
    border-bottom: 2px solid var(--white);
  }
  45% {
    color: var(--colorArticles);
  }
  55% {
    color: black;
  }
  60% {
    border-bottom: 2px solid var(--colorArticles);
  }
  65% {
    border-left: 2px solid var(--colorArticles);
    color: var(--white);
  }
  70% {
    border-left: 2px solid var(--white);
  }
  75% {
    color: black;
  }
  80% {
    border-top: 1px solid var(--colorArticles);
  }
  85% {
    border-top: 1px solid var(--white);
    border-left: 2px solid var(--white);
  }
  90% {
    border-top: 1px solid var(--white);
    border-left: 2px solid var(--colorArticles);
  }
  99% {
    border-top: 1px solid var(--colorArticles);
  }
  100% {
    color: var(--colorArticles);
  }
}
@keyframes btnBlinkL {
  0% {
    color: var(--colorNews);
    border-right: 2px solid var(--colorNews);
  }
  5% {
    border-right: 2px solid var(--black);
  }
  15% {
    color: var(--black);
  }
  25% {
    border-right: 2px solid var(--black);
    color: var(--black);
  }
  30% {
    border-right: 2px solid var(--colorNews);
  }
  35% {
    color: var(--colorNews);
    border-bottom: 2px solid var(--colorNews);
  }
  40% {
    border-bottom: 2px solid var(--black);
  }
  45% {
    color: var(--colorNews);
  }
  55% {
    color: var(--white);
  }
  60% {
    border-bottom: 2px solid var(--colorNews);
  }
  65% {
    border-left: 2px solid var(--colorNews);
    color: var(--black);
  }
  70% {
    border-left: 2px solid var(--black);
  }
  75% {
    color: black;
  }
  80% {
    border-top: 1px solid var(--colorNews);
  }
  85% {
    border-top: 1px solid var(--black);
    border-left: 2px solid var(--black);
  }
  90% {
    border-top: 1px solid var(--black);
    border-left: 2px solid var(--colorNews);
  }
  99% {
    border-top: 1px solid var(--colorNews);
  }
  100% {
    color: var(--colorNews);
  }
}
@keyframes btnBlinkB {
  0% {
    color: var(--colorArticles);
    border-right: 1px solid var(--colorArticles);
  }
  5% {
    border-right: 1px solid var(--white);
  }
  15% {
    color: var(--white);
  }
  25% {
    border-right: 1px solid var(--white);
    color: var(--white);
  }
  30% {
    border-right: 1px solid var(--colorArticles);
  }
  35% {
    color: var(--colorArticles);
    border-bottom: 1px solid var(--colorArticles);
  }
  40% {
    border-bottom: 1px solid var(--white);
  }
  45% {
    color: var(--colorArticles);
  }
  55% {
    color: var(--white);
  }
  60% {
    border-bottom: 1px solid var(--colorArticles);
  }
  65% {
    border-left: 1px solid var(--colorArticles);
    color: var(--white);
  }
  70% {
    border-left: 1px solid var(--white);
  }
  75% {
    color: var(--colorArticles);
  }
  80% {
    border-top: 1px solid var(--colorArticles);
  }
  85% {
    border-top: 1px solid var(--white);
    border-left: 1px solid var(--white);
  }
  90% {
    border-top: 1px solid var(--white);
    border-left: 1px solid var(--colorArticles);
  }
  99% {
    border-top: 1px solid var(--colorArticles);
  }
  100% {
    color: var(--colorArticles);
  }
}
@keyframes glowMovment {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-10px, 4px);
  }
  50% {
    transform: translate(0px, 8px);
  }
  75% {
    transform: translate(5px, 4px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes scanline {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(500%);
  }
}
@keyframes slideKont {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-365px);
  }
}
@keyframes KolorIn {
  0% {
    background-color: var(--colorArticlesA);
  }
  100% {
    background-color: var(--colorSocial);
  }
}
@keyframes hideKont {
  0% {
    transform: translateX(-365px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes KolorOut {
  0% {
    background-color: var(--colorSocial);
  }
  100% {
    background-color: var(--colorArticlesA);
  }
}
@media (max-width: 480px) {
  @keyframes slideKont {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw);
    }
  }
  @keyframes hideKont {
    0% {
      transform: translateX(-100vw);
    }
    100% {
      transform: translateX(0px);
    }
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.buttonM, .nft, .lud, .art, .new, .copy, .copyC, .copyB, .copyA {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  pointer-events: auto;
  background-color: var(--colorArticlesB);
  width: 35px;
  height: 145px;
  padding-top: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--white);
  transition: color 0.15s ease-out, transform 0.2s ease-out;
  border: 1px solid var(--colorArticles);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.buttonM p, .nft p, .lud p, .art p, .new p, .copy p, .copyC p, .copyB p, .copyA p {
  font-family: var(--font-headers);
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: -3px;
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-shadow: -3px 4px 3px var(--colorArticlesD);
}
.buttonM:hover, .nft:hover, .lud:hover, .art:hover, .new:hover, .copy:hover, .copyC:hover, .copyB:hover, .copyA:hover {
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  animation-name: btnBlinkB;
  transform: translate(-6px, 4px);
}

.buttonWrapperB {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.buttonWrapper {
  position: relative;
}

.new, .copy, .copyC, .copyB, .copyA {
  left: 15px;
  top: 7px;
  -webkit-clip-path: polygon(100% 0, 100% 75%, 40% 100%, 0 100%, 0 0);
          clip-path: polygon(100% 0, 100% 75%, 40% 100%, 0 100%, 0 0);
}

.copy, .copyC, .copyB, .copyA {
  -webkit-backdrop-filter: blur(0px);
          backdrop-filter: blur(0px);
  left: 8px;
  top: 12px;
  border: none;
  background-color: var(--colorArticlesE);
  transition: all 0.2s ease-out;
  -webkit-clip-path: polygon(100% 0, 100% 75%, 40% 100%, 0 100%, 0 0);
          clip-path: polygon(100% 0, 100% 75%, 40% 100%, 0 100%, 0 0);
}
.copy:hover, .copyC:hover, .copyB:hover, .copyA:hover {
  animation-iteration-count: none;
  animation-duration: none;
  animation-name: none;
  transform: none;
}

.copyA {
  left: calc(100vw - 43px);
  -webkit-clip-path: polygon(100% 0, 100% 100%, 60% 100%, 0 75%, 0 0);
          clip-path: polygon(100% 0, 100% 100%, 60% 100%, 0 75%, 0 0);
}

.copyB {
  left: 8px;
  top: -157px;
  -webkit-clip-path: polygon(40% 0, 100% 25%, 100% 100%, 0 100%, 0 0);
          clip-path: polygon(40% 0, 100% 25%, 100% 100%, 0 100%, 0 0);
}

.copyC {
  left: calc(100vw - 43px);
  top: -157px;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 25%, 40% 0);
          clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 25%, 40% 0);
}

.glow, .glowC, .glowB, .glowA {
  z-index: -1;
  position: absolute;
  filter: drop-shadow(-5px 5px 2px var(--colorArticlesF));
  transition: all 0.25s ease-out;
}

.buttonM:hover + .glow, .nft:hover + .glow, .lud:hover + .glow, .art:hover + .glow, .buttonM:hover + .glowC, .nft:hover + .glowC, .lud:hover + .glowC, .art:hover + .glowC, .buttonM:hover + .glowB, .nft:hover + .glowB, .lud:hover + .glowB, .art:hover + .glowB, .buttonM:hover + .glowA, .nft:hover + .glowA, .lud:hover + .glowA, .art:hover + .glowA, .new:hover + .glow, .new:hover + .glowC, .new:hover + .glowB, .new:hover + .glowA, .copy:hover + .glow, .copy:hover + .glowC, .copy:hover + .glowB, .copy:hover + .glowA, .copyA:hover + .glow, .copyA:hover + .glowC, .copyA:hover + .glowB, .copyA:hover + .glowA, .copyB:hover + .glow, .copyB:hover + .glowC, .copyB:hover + .glowB, .copyB:hover + .glowA, .copyC:hover + .glow, .copyC:hover + .glowC, .copyC:hover + .glowB, .copyC:hover + .glowA {
  filter: drop-shadow(-3px 3px 2px var(--colorArticlesD));
}

.buttonM.art:hover, .art:hover {
  transform: translate(-6px, 4px);
}

.glowA {
  filter: drop-shadow(5px 5px 2px var(--colorArticlesF));
}

.glowB {
  filter: drop-shadow(-5px -5px 2px var(--colorArticlesF));
}

.glowC {
  filter: drop-shadow(5px -5px 2px var(--colorArticlesF));
}

.art {
  right: 15px;
  top: 7px;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 60% 100%, 0 75%, 0 0);
          clip-path: polygon(100% 0, 100% 100%, 60% 100%, 0 75%, 0 0);
}

.buttonM.art:hover, .art:hover {
  transform: translate(6px, 4px);
}

.buttonM.art:hover + .glow, .art:hover + .glow, .art:hover + .glowA, .art:hover + .glowB, .art:hover + .glowC {
  filter: drop-shadow(3px 3px 2px var(--colorArticlesD));
}

.lud {
  align-items: center;
  left: 15px;
  bottom: 7px;
  -webkit-clip-path: polygon(40% 0, 100% 25%, 100% 100%, 0 100%, 0 0);
          clip-path: polygon(40% 0, 100% 25%, 100% 100%, 0 100%, 0 0);
}

.buttonM.lud:hover, .lud:hover {
  transform: translate(-6px, -4px);
}

.buttonM.lud:hover + .glow, .lud:hover + .glow, .lud:hover + .glowA, .lud:hover + .glowB, .lud:hover + .glowC {
  filter: drop-shadow(-3px -3px 2px var(--colorArticlesD));
}

.nft {
  align-items: center;
  right: 15px;
  bottom: 7px;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 25%, 40% 0);
          clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 25%, 40% 0);
}

.buttonM.nft:hover, .nft:hover {
  transform: translate(6px, -4px);
}

.buttonM.nft:hover + .glow, .nft:hover + .glow, .nft:hover + .glowA, .nft:hover + .glowB, .nft:hover + .glowC {
  filter: drop-shadow(3px -3px 2px var(--colorArticlesD));
}/*# sourceMappingURL=ButtonM.module.css.map */