.wrapperAll {
  position: absolute;
  left: 24%;
  top: 11.7%;

  width: calc(var(--inputWidth) + 24px);
  height: calc(var(--inputHeight) + 4px);

  @media (min-width: 1921px) {
    @media (min-height: 1153px) {
      top: 10.5%;
    }
  }

  @media (max-width: 1600px) {
    top: 80px;
    left: 63.7%;
  }

  @media (max-width: 1440px) {
    left: 59.2%;
  }

  @media (max-width: 1366px) {
    top: 40px;
    left: 57.4%;
    height: calc(var(--inputHeight) - 6px);
  }

  @media (max-width: 1280px) {
    top: 41px;
    left: 54.4%;
    height: calc(var(--inputHeight) - 8px);
  }

  @media (max-width: 1152px) {
    top: 41px;
    left: 51.4%;
    // height: calc(var(--inputHeight) - 40px);
  }

  @media (max-width: 1024px) {
    top: 37px;
    left: 45.8%;
    // height: calc(var(--inputHeight) - 40px);
  }

  @media (max-width: 480px) {
    display: none;
    // height: calc(var(--inputHeight) - 40px);
  }

  //ale coś nie zawerza wysokosci
  // border: 1px solid whitesmoke;
}

.backWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.backWrapperB {
  position: relative;
  height: 100%;
  width: 100%;
  filter: drop-shadow(-5px 5px 8px var(--colorArticlesB));
}

.background {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(var(--inputHeight) + 4px);
  width: calc(var(--inputWidth) + 22px);
  background-color: var(--colorArticles);
  clip-path: polygon(0% 40%, 10% 0%, 100% 0%, 100% 60%, 90% 100%, 0 100%);

  @media (min-width: 1921px) {
    clip-path: polygon(0% 40%, 7% 0%, 100% 0%, 100% 60%, 93% 100%, 0 100%);
  }

  @media (max-width: 1600px) {
    clip-path: polygon(0% 40%, 0% 0%, 100% 0%, 100% 60%, 94% 100%, 0 100%);
  }

  @media (max-width: 1280px) {
    clip-path: polygon(0% 40%, 0% 0%, 100% 0%, 100% 60%, 95% 100%, 0 100%);
  }

  @media (max-width: 1024px) {
    clip-path: polygon(0% 40%, 0% 0%, 100% 0%, 100% 60%, 95% 100%, 0 100%);
  }
}

.bgkS {
  @extend .background;
  @media (max-width: 480px) {
    display: none;
  }
}

.input {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: var(--inputHeight);
  font-family: var(--font-headers);
  width: calc(var(--inputWidth) + 20px);
  padding: 15px 30px;
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: var(--bold);
  text-transform: uppercase;
  clip-path: polygon(0% 40%, 10% 0%, 100% 0%, 100% 60%, 90% 100%, 0 100%);
  border: none;
  appearance: none;
  outline: none;
  background-color: var(--colorArticlesDarkB);
  letter-spacing: 1px;

  &::placeholder {
    font-family: var(--font-headers);
    font-weight: var(--bold);
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--colorGrey100);
    line-height: 1.2rem;
    // width: 100%;
    // height: 100%;
    // border: 1px solid black;

    @media (max-width: 1600px) {
      font-size: 1.1rem;
    }
  }

  &:focus {
    background-color: var(--colorGrey100);
  }

  @media (min-width: 1921px) {
    clip-path: polygon(0% 40%, 7% 0%, 100% 0%, 100% 60%, 93% 100%, 0 100%);
  }

  @media (max-width: 1600px) {
    clip-path: polygon(0% 40%, 0% 0%, 100% 0%, 100% 60%, 94% 100%, 0 100%);
  }

  @media (max-width: 1280px) {
    clip-path: polygon(0% 40%, 0% 0%, 100% 0%, 100% 60%, 95% 100%, 0 100%);
  }
}

.search {
  @extend .input;
  padding: 10px 20px 10px 40px;
  background-image: url(../../../assets/icons/magnifier.svg);
  background-size: 15px;
  background-position: 15px 50%;
  background-repeat: no-repeat;
  @media (max-width: 480px) {
    display: none;
    // height: calc(var(--inputHeight) - 40px);
  }
}

.contact {
  @extend .input;
  clip-path: polygon(0% 40%, 7% 0%, 100% 0%, 100% 60%, 93% 100%, 0 100%);
  width: calc(var(--inputWidth) + 60px);
  @media (max-width: 480px) {
    display: block;
    // height: calc(var(--inputHeight) - 40px);
  }
}

.contactWrap {
  position: relative;
  left: 0;
  top: 0;
  width: calc(var(--inputWidth) + 64px);
  height: calc(var(--inputHeight) + 4px);
}

.contactBgk {
  @extend .background;
  clip-path: polygon(0% 40%, 7% 0%, 100% 0%, 100% 60%, 93% 100%, 0 100%);
  width: calc(var(--inputWidth) + 62px);
}

.comment {
  @extend .input;
  clip-path: polygon(0% 40%, 7% 0%, 100% 0%, 100% 60%, 100% 100%, 0 100%);
  height: 100%;
  width: 100%;
  @media (max-width: 480px) {
    display: block;
    // height: calc(var(--inputHeight) - 40px);
  }
}

.commentWrap {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 5px;
}

.commentBgk {
  @extend .background;
  clip-path: polygon(0% 40%, 7% 0%, 100% 0%, 100% 60%, 100% 100%, 0 100%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}
