.zoneTop {
  text-align: left;
  line-height: 2.1rem;
  color: var(--white);
  display: block;
  height: calc(var(--inputHeight) + 40px);
  width: calc(100% - 40px);
  padding: 20px;
  position: absolute;
  font-size: 1.8rem;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--colorArticlesE);
  border: 1px solid var(--colorArticles);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 500;
  backdrop-filter: blur(8px);

  @media (max-width: 1366px) {
    height: calc(var(--inputHeight) + 30px);
    font-size: var(--fontSizeS);
  }

  @media (max-width: 1024px) {
    font-size: var(--fontSizeXS);
  }

  & p {
    display: block;
  }

  // text-transform: uppercase;
}

.zoneTopRelative {
  @extend .zoneTop;
  height: auto;
}
