.wrapper {
  width: 100%;
  height: 100%;
  // border: 1px solid green;
}

.disabledForm {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.8);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  backdrop-filter: sepia(80%);
}
.form {
  height: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  // border: 1px solid red;
  width: 25%;
  height: 10vh;
  padding: 10px;
}

.rightWrap {
  // border: 1px solid red;
  padding: 10px;
  width: 75%;
  height: 10vh;
  border-left: 1px solid var(--colorArticles);
}

.titleWrap {
  height: 4rem;
  width: 40%;
  position: absolute;
  left: 50%;
  top: 50%;
  letter-spacing: 2px;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  // border: 1px solid red;
  text-align: center;

  @media (max-width: 1600px) {
    height: 3.2rem;
  }
}

.title {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: var(--bold);
  color: var(--white);
  font-family: var(--font-headers);
  text-transform: uppercase;
  letter-spacing: 1px;
  user-select: none;

  & b {
    opacity: 0.7;
    letter-spacing: -2px;
    @media (max-width: 1366px) {
      letter-spacing: -1px;
    }
  }

  @media (max-width: 1600px) {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }

  @media (max-width: 1152px) {
    font-size: 1.2rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
}
