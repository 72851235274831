.heading {
  // display: block;
  text-align: left;
  padding: 15px;
  padding-left: 50px;
  padding-right: 50px;
  display: block;
  font-size: var(--fontSizeL);
  font-weight: var(--bold);
  color: var(--black);
  margin-bottom: 5px;
  font-family: var(--font-headers);
  text-transform: uppercase;

  @media (max-width: 1600px) {
    font-size: 2.2rem;
  }

  @media (max-width: 1440px) {
    font-size: 2rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.8rem;
  }
  @media (max-width: 1152px) {
    font-size: 1.6rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
}

.card {
  @extend .heading;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 2rem;
  height: 45%;
  color: black;

  @media (max-width: 1366px) {
    font-size: 1.8rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.6rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
}

.detailPage {
  @extend .heading;
  letter-spacing: 1px;
  color: white;
}

.position {
  @extend .heading;
  letter-spacing: 1px;
  color: var(--colorArticles);
  text-shadow: -4px 4px 2px rgb(0, 0, 0);
  // border: 1px solid red;
  // text-align: center;

  @media (max-width: 480px) {
    display: block;
    margin: 0;
    padding: 0;
    width: 60%;
    text-shadow: -4px 4px 2px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    letter-spacing: 2px;
    text-align: center;
  }
}
