.slider {
  position: relative;
  width: 100%;
  height: 100%;
  // border: 1px solid var(--colorArticles);
  // border-radius: 7px;
  perspective: 1700px;
  transform-style: preserve-3d;
  pointer-events: none;
}
