.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../../assets/images/detailsNewsBgk.webp);
  opacity: 0.75;
  z-index: -1;
}
