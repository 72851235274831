@import '../../../scss/animations';

$btnWidth: 210px;
$btnHeight: 40px;
.button {
  margin: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: right;
  align-items: center;
  color: var(--white);
  text-shadow: -3px 4px 3px var(--colorArticlesD);
  background-color: var(--colorArticlesB);
  width: var(--buttonWidth);
  height: var(--buttonHeight);
  clip-path: polygon(90% 0%, 100% 30%, 100% 100%, 10% 100%, 0% 70%, 0 0);
  font-family: var(--font-headers);
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 20px;
  transition: color 0.15s ease-out, transform 0.2s ease-out;
  border-left: 2px solid var(--colorArticles);
  border-right: 2px solid var(--colorArticles);
  border-bottom: 2px solid var(--colorArticles);
  border-top: 2px solid var(--colorArticles);
  backdrop-filter: blur(2px);
  // z-index: 1;

  &:hover {
    animation-iteration-count: 1;
    animation-duration: 0.5s;
    animation-name: btnBlink;
    transform: translate(-3px, 4px);
  }
}
.button:hover + .glow {
  filter: drop-shadow(-3px 3px 2px var(--colorArticlesD));
}

.button:hover + .glowComm {
  filter: drop-shadow(-3px 3px 2px var(--colorArticlesD));
}

.kontakt:hover + .glowKont {
  filter: drop-shadow(3px 3px 2px var(--colorArticlesD));
}

.kontaktM:hover + .glowKontM {
  filter: drop-shadow(3px 3px 2px var(--colorArticlesD));
}

.login {
  @extend .button;
  width: var(--btnloginWidth);
  border-left: 2px solid var(--white);
  border-right: 2px solid var(--white);
  border-bottom: 2px solid var(--white);
  border-top: 2px solid var(--white);
  background-color: rgba(255, 255, 255, 0.4);
  justify-content: center;
  padding-right: 0;
  text-shadow: -3px 4px 3px var(--black);

  &:hover {
    animation-iteration-count: 1;
    animation-duration: 0.5s;
    animation-name: btnBlinkL;
    transform: translate(-3px, 4px);
  }
}

.buttonFlex {
  @extend .button;
  font-size: 1.2rem;
  // font-size: 1.2rem;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
}

.secondary {
  @extend .button;
  position: absolute;
  top: 3px;
  left: 11px;
  // transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  justify-content: center;
  width: calc(var(--buttonWidth) / 1.5);
  height: var(--buttonHeight);

  &:hover {
    animation-iteration-count: 1;
    animation-duration: 0.5s;
    animation-name: btnBlinkB;
    transform: translate(-3px, 4px);
  }

  @media (max-width: 1600px) {
    font-weight: 300;
    font-size: 1.3rem;
  }
  @media (max-width: 1024px) {
    justify-content: center;
    margin: 15px;
    font-size: 1.1rem;
  }

  @media (max-width: 480px) {
    justify-content: center;
    position: absolute;
    margin: 0px;
    font-size: 1rem;
    width: calc(var(--buttonWidth) / 1.8);
    height: calc(var(--buttonHeight) - 6px);
    left: calc(50% - (var(--buttonWidth) / 3.6));
  }
}

// .secondaryB {
//   @extend .secondary;
//   top: 3px;
//   left: 11px;
//   // position: relative;
// }

// .secondaryC {
//   @extend .secondaryB;
//   // position: relative;
// }

.kontakt {
  // position: absolute;
  @extend .button;
  width: var(--buttonHeight);
  height: var(--buttonWidth);
  justify-content: center;
  padding-right: 0;
  margin: 0;
  letter-spacing: -2px;
  clip-path: polygon(35% 100%, 0 92%, 0 8%, 35% 0, 100% 0, 100% 100%);
  // transform: rotate(90deg);

  @media (max-width: 1366px) {
    width: calc(var(--buttonHeight) - 5px);
    height: calc(var(--buttonWidth) - 30px);
    font-size: 1.2rem;
  }

  & p {
    writing-mode: vertical-rl;
    text-orientation: upright;
  }

  &:hover {
    transform: translate(3px, 4px);
  }
}

.kontaktM {
  @extend .kontakt;
  display: none;
  @media (max-width: 480px) {
    justify-content: center;
    display: flex;
  }
}

// .wysylka {
//   @extend .kontakt;
//   width: var(--buttonWidth);
//   height: var(--buttonHeight);
//   clip-path: polygon(90% 0%, 100% 30%, 100% 100%, 10% 100%, 0% 70%, 0 0);
//   letter-spacing: 1px;
//   & p {
//     text-orientation: mixed;
//     writing-mode: horizontal-tb;
//   }

//   // @media (max-width: 1366px) {
//   //   width: calc(var(--buttonWidth) - 30px);
//   //   height: calc(var(--buttonHight) - 5px);
//   //   font-size: 1.2rem;
//   // }
// }

.buttonDetails {
  @extend .button;
  position: absolute;
  top: 0px;
  left: 11px;
  margin: 0;
}

.comment {
  @extend .button;
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
  margin: 0;
  padding-right: 0px;
  padding-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonWrapper {
  position: relative;

  // border: 1px solid yellowgreen;
  // // @media (max-width: 480px) {
  //   position: unset;
  // border: 1px solid red;
  //   width: $btnWidth;
  //   height: $btnHeight;
  //   // z-index: 2;
  // }
}

.buttonLWrapper {
  position: absolute;
  top: 2px;
  right: 0;
  transform: translate(100%, 0);
  @media (max-width: 480px) {
    right: 50%;
    transform: translate(0, 50%);
  }
}

.buttonSWrapper {
  width: calc(var(--buttonWidth) / 1.5 + 10px);
  height: calc(var(--buttonHeight) + 10px);
  position: absolute;
  bottom: 5px;
  left: 5px;
  // border: 1px solid red;
  z-index: 1; //powrócił stary błąd chroma tutaj sprawę rozwiążał podwyższenie z-index hover z przyciksu wpływał na zmiany w wyświetlaniu newsDAte oraz nie respektowanie path i hidden overflov na rogu karty
}

.buttonDWrapper {
  @extend .buttonSWrapper;
  width: var(--buttonWidth);
  height: var(--buttonHeight);
  bottom: 50px;
  left: 320px;
  // border: 1px solid red;
  @media (max-width: 480px) {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.buttonFlexWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  // align-self: center;
  // flex-grow: 2;
  // border: 1px solid greenyellow;
}

.copy {
  width: var(--buttonWidth);
  height: var(--buttonHeight);
  clip-path: polygon(90% 0%, 100% 30%, 100% 100%, 10% 100%, 0% 70%, 0 0);
  background-color: var(--colorArticlesE);
  transition: all 0.2s ease-out;
}

.copyL {
  @extend .copy;
  width: var(--btnloginWidth);
  position: absolute;
  left: -2px;
  top: 11px;

  background-color: rgba(255, 255, 255, 0.2);
}

.copyS {
  @extend .copy;
  position: absolute;
  left: 0;
  top: 6px;
  background-color: var(--colorArticlesB);
  width: calc(var(--buttonWidth) / 1.5);

  @media (max-width: 480px) {
    left: calc(50% - (var(--buttonWidth) / 3.6));
    width: calc(var(--buttonWidth) / 1.8);
    height: calc(var(--buttonHeight) - 6px);
  }
}

.copyKont {
  // position: absolute;
  width: var(--buttonHeight);
  height: var(--buttonWidth);
  clip-path: polygon(35% 100%, 0 92%, 0 8%, 35% 0, 100% 0, 100% 100%);
  background-color: var(--colorArticlesB);
  transition: all 0.2s ease-out;

  @media (max-width: 1366px) {
    width: calc(var(--buttonHeight) - 5px);
    height: calc(var(--buttonWidth) - 30px);
  }
}

.copyKontM {
  @extend .copyKont;
  display: none;
  @media (max-width: 480px) {
    display: block;
  }
}

.copyWys {
  width: var(--buttonWidth);
  height: var(--buttonHeight);
  clip-path: polygon(90% 0%, 100% 30%, 100% 100%, 10% 100%, 0% 70%, 0 0);
  background-color: var(--colorArticlesB);
  transition: all 0.2s ease-out;
}

.copyMe {
  @extend .copy;
  width: $btnWidth;
  height: $btnHeight;
  background-color: var(--colorArticlesE);
}

.copyComm {
  @extend .copy;
  background-color: var(--colorArticlesB);
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
}

.glow {
  z-index: -1;
  position: absolute;
  left: 5px;
  top: 4px;
  filter: drop-shadow(-6px 6px 2px var(--colorArticlesF));
  transition: all 0.25s ease-out;
}

.glowS {
  @extend .glow;
  // width: calc(var(--buttonWidth) / 1.5);
  @media (max-width: 480px) {
    left: calc(50% - (var(--buttonWidth) / 3.6) - 5px);
    width: calc(var(--buttonWidth) / 1.8);
    height: calc(var(--buttonHeight) - 6px);
  }
}

.glowL {
  @extend .glow;
  width: var(--btnloginWidth);
  filter: drop-shadow(-6px 6px 2px rgba(255, 255, 255, 0.4));
}

.glowKont {
  z-index: -1;
  position: absolute;
  left: 5px;
  top: 4px;
  filter: drop-shadow(6px 6px 2px var(--colorArticlesF));
  transition: all 0.25s ease-out;
}

.glowMe {
  @extend .glow;
  filter: drop-shadow(-6px 6px 2px var(--colorArticlesC));
}

.glowKontM {
  @extend .glowKont;
  display: none;
  @media (max-width: 480px) {
    display: block;
  }
}

.glowComm {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: -5px;
  top: 4px;
  filter: drop-shadow(-6px 6px 2px var(--colorArticlesF));
  transition: all 0.25s ease-out;
}

.menu {
  @extend .button;
  width: $btnWidth;
  height: $btnHeight;
}

.news {
  transform-origin: top left;
  padding: 0;
  margin: 0;
  left: 30px;
  // top: 10px;
  position: absolute;
  transform: rotate(60deg);
}

.art {
  transform-origin: top right;
  padding: 0;
  margin: 0;
  right: 30px;
  // top: 10px;
  position: absolute;
  transform: rotate(-60deg);
}
