@keyframes btnBlink {
  0% {
    color: var(--colorArticles);
    border-right: 2px solid var(--colorArticles);
  }
  5% {
    border-right: 2px solid var(--white);
  }
  15% {
    color: var(--white);
  }

  25% {
    border-right: 2px solid var(--white);
    color: var(--white);
  }
  30% {
    border-right: 2px solid var(--colorArticles);
  }

  35% {
    color: var(--colorArticles);
    border-bottom: 2px solid var(--colorArticles);
  }
  40% {
    border-bottom: 2px solid var(--white);
  }

  45% {
    color: var(--colorArticles);
  }

  55% {
    color: black;
  }
  60% {
    border-bottom: 2px solid var(--colorArticles);
  }

  65% {
    border-left: 2px solid var(--colorArticles);
    color: var(--white);
  }
  70% {
    border-left: 2px solid var(--white);
  }

  75% {
    color: black;
  }
  80% {
    border-top: 1px solid var(--colorArticles);
  }
  85% {
    border-top: 1px solid var(--white);
    border-left: 2px solid var(--white);
  }
  90% {
    border-top: 1px solid var(--white);
    border-left: 2px solid var(--colorArticles);
  }
  99% {
    border-top: 1px solid var(--colorArticles);
  }

  100% {
    color: var(--colorArticles);
  }
}

@keyframes btnBlinkL {
  0% {
    color: var(--colorNews);
    border-right: 2px solid var(--colorNews);
  }
  5% {
    border-right: 2px solid var(--black);
  }
  15% {
    color: var(--black);
  }

  25% {
    border-right: 2px solid var(--black);
    color: var(--black);
  }
  30% {
    border-right: 2px solid var(--colorNews);
  }

  35% {
    color: var(--colorNews);
    border-bottom: 2px solid var(--colorNews);
  }
  40% {
    border-bottom: 2px solid var(--black);
  }

  45% {
    color: var(--colorNews);
  }

  55% {
    color: var(--white);
  }
  60% {
    border-bottom: 2px solid var(--colorNews);
  }

  65% {
    border-left: 2px solid var(--colorNews);
    color: var(--black);
  }
  70% {
    border-left: 2px solid var(--black);
  }

  75% {
    color: black;
  }
  80% {
    border-top: 1px solid var(--colorNews);
  }
  85% {
    border-top: 1px solid var(--black);
    border-left: 2px solid var(--black);
  }
  90% {
    border-top: 1px solid var(--black);
    border-left: 2px solid var(--colorNews);
  }
  99% {
    border-top: 1px solid var(--colorNews);
  }

  100% {
    color: var(--colorNews);
  }
}

@keyframes btnBlinkB {
  0% {
    color: var(--colorArticles);
    border-right: 1px solid var(--colorArticles);
  }
  5% {
    border-right: 1px solid var(--white);
  }
  15% {
    color: var(--white);
  }

  25% {
    border-right: 1px solid var(--white);
    color: var(--white);
  }
  30% {
    border-right: 1px solid var(--colorArticles);
  }

  35% {
    color: var(--colorArticles);
    border-bottom: 1px solid var(--colorArticles);
  }
  40% {
    border-bottom: 1px solid var(--white);
  }

  45% {
    color: var(--colorArticles);
  }

  55% {
    color: var(--white);
  }
  60% {
    border-bottom: 1px solid var(--colorArticles);
  }

  65% {
    border-left: 1px solid var(--colorArticles);
    color: var(--white);
  }
  70% {
    border-left: 1px solid var(--white);
  }

  75% {
    color: var(--colorArticles);
  }
  80% {
    border-top: 1px solid var(--colorArticles);
  }
  85% {
    border-top: 1px solid var(--white);
    border-left: 1px solid var(--white);
  }
  90% {
    border-top: 1px solid var(--white);
    border-left: 1px solid var(--colorArticles);
  }
  99% {
    border-top: 1px solid var(--colorArticles);
  }

  100% {
    color: var(--colorArticles);
  }
}

@keyframes glowMovment {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-10px, 4px);
  }
  50% {
    transform: translate(0px, 8px);
  }

  75% {
    transform: translate(5px, 4px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes scanline {
  0% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(500%);
  }
}

@keyframes slideKont {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-365px);
  }
}

@keyframes KolorIn {
  0% {
    background-color: var(--colorArticlesA);
  }

  100% {
    background-color: var(--colorSocial);
  }
}

@keyframes hideKont {
  0% {
    transform: translateX(-365px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes KolorOut {
  0% {
    background-color: var(--colorSocial);
  }

  100% {
    background-color: var(--colorArticlesA);
  }
}
////////////////////////////////////////////////////////////////
@media (max-width: 480px) {
  @keyframes slideKont {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-100vw);
    }
  }

  @keyframes hideKont {
    0% {
      transform: translateX(-100vw);
    }

    100% {
      transform: translateX(0px);
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
