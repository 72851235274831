.buttonicon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  width: 67px;
  height: 67px;
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  background-color: var(--colorArticles);
  background-image: url('../../../assets/icons/book.svg');
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 40%;
  margin-bottom: 20px;
  transition: 0.2s ease-in;
}

.buttonicon:hover {
  background-color: var(--colorArticles);
}

.logout {
  @extend .buttonicon;
  background-image: url('../../../assets/icons/logout.svg');
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 40%;
}

.pen {
  @extend .buttonicon;
  background-image: url('../../../assets/icons/pen.svg');
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 40%;
}

.people {
  @extend .buttonicon;
  background-image: url('../../../assets/icons/people.svg');
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 60%;
}

.plus {
  @extend .buttonicon;
  background-image: url('../../../assets/icons/plus.svg');
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 40%;
}

.social {
  @extend .buttonicon;
  clip-path: polygon(10% 0%, 90% 0%, 100% 15%, 100% 85%, 90% 100%, 10% 100%, 0% 85%, 0% 15%);
  background-color: var(--colorBgkVD);
  background-image: none;
  background-repeat: none;
  background-position: none;
  background-size: auto;
  margin: 0;
  @media (max-width: 480px) {
    background-color: var(--colorArticles);
  }
}

.git {
  @extend .social;
  // background-image: url('../../../assets/icons/GitHubBig.svg');
  // background-color: var(--white);
  padding: 6px;
  width: 90px;
  height: 45px;
  @media (max-width: 1600px) {
    width: 70px;
    height: 40px;
  }
  @media (max-width: 1440px) {
    width: 65px;
  }

  @media (max-width: 1366px) {
    width: 60px;
  }

  @media (max-width: 1152px) {
    width: 40px;
    padding: 4px;
  }

  @media (max-width: 480px) {
    width: 38px;
    height: 38px;
    padding: 4px;
  }
}

.github {
  width: 100%;
  height: 100%;
  mask-image: url('../../../assets/icons/GitHubBig.svg');
  mask-size: 85%;
  mask-repeat: no-repeat;
  mask-position: 50%, 60%;
  background-color: white;
  transition: 0.2s ease-in;

  @media (max-width: 1600px) {
    mask-size: 95%;
  }
  @media (max-width: 1152px) {
    mask-image: url('../../../assets/icons/GitHubSmall.svg');
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: 50%, 50%;
  }
  @media (max-width: 480px) {
    background-color: black;
  }
}

.vim {
  @extend .social;
  padding: 6px;
  width: 90px;
  height: 45px;
  @media (max-width: 1600px) {
    width: 70px;
    height: 40px;
  }
  @media (max-width: 1440px) {
    width: 65px;
  }

  @media (max-width: 1366px) {
    width: 60px;
  }
  @media (max-width: 1152px) {
    width: 40px;
    padding: 4px;
  }
  @media (max-width: 480px) {
    width: 38px;
    height: 38px;
    padding: 4px;
  }
}

.vimeo {
  width: 100%;
  height: 100%;
  mask-image: url('../../../assets/icons/VimeoBig.svg');
  mask-size: 85%;
  mask-repeat: no-repeat;
  mask-position: 50%, 60%;
  background-color: white;
  transition: 0.2s ease-in;

  @media (max-width: 1600px) {
    mask-size: 95%;
  }

  @media (max-width: 1152px) {
    mask-image: url('../../../assets/icons/VimeoSmall.svg');
    mask-size: 80%;
    mask-repeat: no-repeat;
    mask-position: 50%, 50%;
  }
  @media (max-width: 480px) {
    background-color: black;
  }
}

.link {
  @extend .social;
  padding: 4px;
  padding-top: 7px;
  padding-left: 5px;
  width: 90px;
  height: 45px;
  @media (max-width: 1600px) {
    width: 70px;
    height: 40px;
  }
  @media (max-width: 1440px) {
    width: 65px;
  }

  @media (max-width: 1366px) {
    width: 60px;
  }
  @media (max-width: 1152px) {
    width: 40px;
    padding: 4px;
  }

  @media (max-width: 480px) {
    width: 38px;
    height: 38px;
    padding: 4px;
  }
}

.linkedin {
  width: 100%;
  height: 100%;
  mask-image: url('../../../assets/icons/LinkedinBig.svg');
  mask-size: 90%;
  mask-repeat: no-repeat;
  mask-position: 50%, 50%;
  background-color: white;
  transition: 0.2s ease-in;

  @media (max-width: 1600px) {
    mask-size: 100%;
  }
  @media (max-width: 1152px) {
    mask-image: url('../../../assets/icons/LinkedinSmall.svg');
    mask-size: 80%;
    mask-repeat: no-repeat;
    mask-position: 50%, 50%;
  }
  @media (max-width: 480px) {
    background-color: black;
  }
}

.you {
  @extend .social;
  padding: 4px;
  padding-top: 8px;
  padding-left: 5px;
  width: 90px;
  height: 45px;
  @media (max-width: 1600px) {
    width: 70px;
    height: 40px;
  }
  @media (max-width: 1440px) {
    width: 65px;
  }

  @media (max-width: 1366px) {
    width: 60px;
  }

  @media (max-width: 1152px) {
    width: 40px;
    padding: 4px;
  }

  @media (max-width: 480px) {
    width: 38px;
    height: 38px;
    padding: 4px;
  }
}

.youtube {
  width: 100%;
  height: 100%;
  mask-image: url('../../../assets/icons/YoutubeBig.svg');
  mask-size: 90%;
  mask-repeat: no-repeat;
  mask-position: 50%, 50%;
  background-color: white;
  transition: 0.2s ease-in;

  @media (max-width: 1600px) {
    mask-size: 100%;
  }

  @media (max-width: 1152px) {
    mask-image: url('../../../assets/icons/YT.svg');
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: 50%, 50%;
  }

  @media (max-width: 480px) {
    background-color: black;
  }
}

.twit {
  @extend .social;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 15px;
  width: 110px;
  height: 45px;

  @media (max-width: 1600px) {
    width: 70px;
    height: 40px;
  }
  @media (max-width: 1440px) {
    width: 65px;
  }
  @media (max-width: 1366px) {
    width: 60px;
  }

  @media (max-width: 1152px) {
    width: 40px;
    padding: 4px;
  }

  @media (max-width: 480px) {
    width: 38px;
    height: 38px;
    padding: 4px;
  }
}

.twitter {
  width: 100%;
  height: 100%;
  mask-image: url('../../../assets/icons/Twitter.svg');
  mask-size: 75%;
  mask-repeat: no-repeat;
  mask-position: 50%, 50%;
  background-color: white;
  transition: 0.2s ease-in;

  @media (max-width: 1600px) {
    mask-size: 45%;
  }

  @media (max-width: 1440px) {
    mask-size: 70%;
  }

  @media (max-width: 480px) {
    background-color: black;
    mask-size: 75%;
  }
}

.buttonicon:hover > .github {
  background-color: black;
  @media (max-width: 480px) {
    background-color: white;
  }
}

.buttonicon:hover > .twitter {
  background-color: black;
  @media (max-width: 480px) {
    background-color: white;
  }
}

.buttonicon:hover > h3 {
  color: black;
  @media (max-width: 480px) {
    background-color: white;
  }
}

.buttonicon:hover > .vimeo {
  background-color: black;
  @media (max-width: 480px) {
    background-color: white;
  }
}

.buttonicon:hover > .youtube {
  background-color: black;
  @media (max-width: 480px) {
    background-color: white;
  }
}

.buttonicon:hover > .linkedin {
  background-color: black;
  @media (max-width: 480px) {
    background-color: white;
  }
}
