@import '../../../scss/animations';

.wrapperFooter {
  margin-bottom: 10px;
  margin-top: 40px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 90%;
  height: 107px;
  transform: translate(-50%, 0%);
  // opacity: 0.4;
  // border: 1px solid var(--white);
  //   overflow: hidden;

  @media (max-width: 1600px) {
    width: 82%;
  }

  @media (max-width: 1440px) {
    left: 48%;
  }

  @media (max-width: 1366px) {
    margin-bottom: 5px;
    transform: translate(-50%, 0%) scale(95%);
  }
  @media (max-width: 1280px) {
    width: 85%;
    transform: translate(-50%, 0%) scale(100%);
    left: 44%;
  }

  @media (max-width: 1024px) {
    transform: translate(-50%, 0%) scaleY(90%);
    width: 86%;
    bottom: -4px;
    left: 41%;
  }

  @media (max-width: 480px) {
    overflow: hidden;
    transform: translate(-50%, 0%) scaleY(100%);
    left: 50%;
    bottom: 0;
    width: 100%;
    height: 7%;
    margin-bottom: 0px;
  }
}

.innerWrapper {
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, 0%);
  width: 80%;
  height: 100%;

  // transform: translate(-50%, 0%);
  // border: 1px solid white;

  @media (max-width: 480px) {
    right: calc(50% - 2px);
    width: calc(100% - (20% + 53px));
  }
}

.socialWrapper {
  position: absolute;
  width: calc(
    100% - 88px - 31% * 1.25
  ); // a to z czego wynika bo DecorOne tyle tylko że to nie koreponduje ze sobą w innych rozdziałkach?
  padding-right: 140px;
  padding-left: 10px;
  height: 55px;
  left: 35px;
  bottom: 7px;
  flex-direction: row;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 5px solid var(--colorArticles);

  & a {
    display: block;
  }
  @media (max-width: 1600px) {
    padding-right: 70px;
  }

  @media (max-width: 1366px) {
    padding-right: 58px;
    padding-left: 4px;
  }

  @media (max-width: 1152px) {
    padding-right: 75px;
    padding-left: 4px;
  }

  @media (max-width: 1024px) {
    padding-right: 65px;
    padding-left: 4px;
  }

  @media (max-width: 480px) {
    border-right: none;
    // border: 1px solid greenyellow;
    width: calc(100% - 10px);
    height: 100%;
    padding: 0;
    margin: 0;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 43%);
  }
}

.footerTitle {
  position: absolute;
  text-align: right;
  right: 47px;
  bottom: 10px;
  display: block;
  font-size: 1.3rem;
  font-weight: 300;
  color: var(--colorArticles);
  margin-bottom: 5px;
  font-family: var(--font-headers);
  text-transform: uppercase;
  text-shadow: -1px 2px 1px rgba(22, 22, 22, 0.4);
  letter-spacing: 1px;

  @media (max-width: 1536px) {
    font-size: 1.2rem;
  }

  @media (max-width: 1440px) {
    right: 45px;
    bottom: 14px;
    font-size: 1.1rem;
  }

  @media (max-width: 1366px) {
    font-size: 1rem;
  }

  @media (max-width: 1152px) {
    font-size: 0.9rem;
    right: 42px;
    bottom: 18px;
  }

  @media (max-width: 1024px) {
    letter-spacing: 0px;
  }

  @media (max-width: 480px) {
    display: none;
  }
}

.footerTitleM {
  display: none;

  @media (max-width: 480px) {
    position: absolute;
    // border: 1px solid red;
    width: 100%;
    height: 11px;
    font-size: 0.7rem;
    left: 0;
    text-align: center;
    bottom: 0px;
    display: block;
    font-weight: 300;
    color: var(--colorArticles);
    font-family: var(--font-headers);
    text-transform: uppercase;
    text-shadow: -1px 2px 1px rgba(22, 22, 22, 0.4);
    letter-spacing: 1px;
    display: block;
  }
}

.line1 {
  position: absolute;
  left: calc(50% + 1px);
  bottom: 0px;
  transform: translateX(-50%);
  width: calc(100% - 77px);
  border-top: 2px solid var(--black);
  @media (max-width: 480px) {
    display: none;
  }
}

.line2 {
  position: absolute;
  bottom: 0px;
  right: 19px;
  width: 20px;
  border-top: 2px solid var(--black);
  transform: rotate(-45deg);
  transform-origin: top left;
  @media (max-width: 480px) {
    display: none;
  }
}

.line2A {
  position: absolute;
  bottom: 14px;
  right: -5px;
  width: 20px;
  border-top: 1px solid var(--blackA);
  transform: rotate(45deg);
  transform-origin: top left;
  @media (max-width: 480px) {
    display: none;
  }
}

.line3 {
  position: absolute;
  bottom: 15px;
  right: 24px;
  height: 54px;
  border-right: 2px solid var(--black);
  transform-origin: top right;
  @media (max-width: 480px) {
    display: none;
  }
}

.line3A {
  position: absolute;
  bottom: 15px;
  right: 14px;
  height: 54px;
  border-right: 1px solid var(--blackA);
  transform-origin: top right;
  @media (max-width: 480px) {
    display: none;
  }
}

.line4 {
  position: absolute;
  top: 38px;
  right: 24px;
  width: 20px;
  border-top: 2px solid var(--black);
  transform: rotate(45deg);
  transform-origin: top right;
  @media (max-width: 480px) {
    display: none;
  }
}

.line4A {
  position: absolute;
  top: 24px;
  right: 1px;
  width: 20px;
  border-top: 1px solid var(--blackA);
  transform: rotate(-45deg);
  transform-origin: top right;
  @media (max-width: 480px) {
    display: none;
  }
}

.line4B {
  position: absolute;
  top: -1px;
  right: 24px;
  width: 20px;
  border-bottom: 1px solid var(--blackA);
  transform-origin: top right;
  transform: rotate(-45deg);

  @media (max-width: 1366px) {
    display: none;
  }
  @media (max-width: 480px) {
    display: none;
  }
}

.line5 {
  position: absolute;
  top: 23px;
  right: 38px;
  width: calc(31% * 1.25);
  border-bottom: 2px solid var(--black);
  @media (max-width: 480px) {
    display: none;
  }
}

.line5A {
  position: absolute;
  top: 13px;
  right: 38px;
  width: calc(31% * 1.25);
  border-bottom: 1px solid var(--blackA);

  @media (max-width: 1366px) {
    display: none;
  }
  @media (max-width: 480px) {
    display: none;
  }
}

.line6 {
  position: absolute;
  top: 24px;
  right: calc(38px + 31% * 1.25);
  width: 20px;
  border-bottom: 2px solid var(--black);
  transform-origin: top right;
  transform: rotate(-45deg);
  @media (max-width: 480px) {
    display: none;
  }
}

.line6A {
  position: absolute;
  top: 14px;
  right: calc(38px + 31% * 1.25);
  width: 20px;
  border-bottom: 1px solid var(--blackA);
  transform-origin: top right;
  transform: rotate(45deg);

  @media (max-width: 1440px) {
    display: none;
  }
  @media (max-width: 480px) {
    display: none;
  }
}

.line7 {
  position: absolute;
  top: 38px;
  right: calc(51px + 31% * 1.25);
  width: calc(100% - 92px - 31% * 1.25);
  transform-origin: top right;
  border-bottom: 2px solid var(--black);
  @media (max-width: 480px) {
    display: none;
  }
}

.line8 {
  position: absolute;
  top: 51px;
  left: 28px;
  width: 20px;
  border-bottom: 2px solid var(--black);
  transform: rotate(-45deg);
  transform-origin: bottom left;
  @media (max-width: 480px) {
    display: none;
  }
}

.line8A {
  position: absolute;
  top: 38px;
  left: 1px;
  width: 20px;
  border-bottom: 1px solid var(--blackA);
  transform: rotate(45deg);
  transform-origin: bottom left;
  @media (max-width: 480px) {
    display: none;
  }
}

.line9 {
  position: absolute;
  bottom: 16px;
  left: 26px;
  height: 39px;
  border-left: 2px solid var(--black);
  @media (max-width: 480px) {
    display: none;
  }
}

.line9A {
  position: absolute;
  bottom: 16px;
  left: 15px;
  height: 40px;
  border-left: 1px solid var(--blackA);
  @media (max-width: 480px) {
    display: none;
  }
}

.line10 {
  position: absolute;
  bottom: 15px;
  left: 25px;
  width: 20px;
  border-bottom: 2px solid var(--black);
  transform: rotate(45deg);
  transform-origin: bottom left;
  @media (max-width: 480px) {
    display: none;
  }
}

.line10A {
  position: absolute;
  bottom: 1px;
  left: 2px;
  width: 20px;
  border-bottom: 1px solid var(--blackA);
  transform: rotate(-45deg);
  transform-origin: bottom left;
  @media (max-width: 480px) {
    display: none;
  }
}

.tittle1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  position: absolute;
  top: 50%;
  right: 5px;
  width: 75px;
  height: 50px;
  transform: translate(0, -50%);
  color: var(--colorArticles);
  font-family: var(--font-headers);
  font-size: 1.5rem;
  // border: 1px solid white;
  margin: auto;

  @media (max-width: 1600px) {
    font-size: 1.3rem;
    width: 55px;
    font-weight: 300;
  }

  @media (max-width: 1600px) {
    font-size: 1.3rem;
    width: 55px;
    font-weight: 300;
  }

  @media (max-width: 1152px) {
    font-size: 1.2rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.1rem;
  }
  @media (max-width: 480px) {
    display: none;
  }
}

.bgkWrap {
  position: absolute;
  width: calc(31% * 1.25 + 17px);
  height: 71px;
  left: calc(100% - 48px - 31% * 1.25);
  bottom: 7px;
  clip-path: polygon(0 20%, 3% 0, 97% 0, 100% 20%, 100% 80%, 98% 100%, 0 100%);
  overflow: hidden;

  @media (min-width: 1921px) {
    clip-path: polygon(0 20%, 2.5% 0, 98% 0, 100% 20%, 100% 80%, 98% 100%, 0 100%);
  }

  @media (max-width: 1600px) {
    clip-path: polygon(0 20%, 3.3% 0, 97% 0, 100% 20%, 100% 80%, 97.6% 100%, 0 100%);
  }

  @media (max-width: 1440px) {
    clip-path: polygon(0 20%, 3.5% 0, 97% 0, 100% 20%, 100% 80%, 97.5% 100%, 0 100%);
  }

  @media (max-width: 1280px) {
    clip-path: polygon(0 20%, 3.8% 0, 96.8% 0, 100% 20%, 100% 80%, 97.2% 100%, 0 100%);
  }

  @media (max-width: 1024px) {
    clip-path: polygon(0 20%, 4.5% 0, 96% 0, 100% 20%, 100% 80%, 96% 100%, 0 100%);
  }
  @media (max-width: 480px) {
    clip-path: polygon(0 15%, 3% 0, 97% 0, 100% 15%, 100% 100%, 0 100%);
    width: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;
  }
}

.scanline {
  width: 100%;
  height: 35%;
  // z-index: 3;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    var(--colorArticlesA) 10%,
    rgba(49, 0, 0, 0.1) 100%
  );
  opacity: 0.55;
  position: absolute;
  bottom: 100%;
  transform: translateY(0);
  will-change: transform;
  animation: scanline 7s linear infinite;
}

.background {
  position: absolute;
  background-color: var(--colorArticlesB);
  width: 100%;
  height: 100%;
}

.lines {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(24, 0, 0, 0) 50%, var(--colorArticlesB) 50%);
  background-size: 100% 4px;
  opacity: 0.4;
}

.lineA {
  position: absolute;
  width: calc(31% * 1.25 + 5px);
  left: calc(100% - 46px - 31% * 1.25);
  bottom: 7px;
  border-bottom: 2px solid var(--colorArticles);
  @media (max-width: 480px) {
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid var(--colorArticles);
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.lineB {
  position: absolute;
  width: 15px;
  left: calc(100% - 42px);
  bottom: 7px;
  border-bottom: 2px solid var(--colorArticles);
  transform: rotate(-45deg);
  transform-origin: center left;
  @media (max-width: 480px) {
    display: none;
  }
}

.lineC {
  position: absolute;
  height: 48px;
  right: 31px;
  bottom: 18px;
  border-right: 2px solid var(--colorArticles);
  transform-origin: center right;
  @media (max-width: 480px) {
    border-right: 1px solid var(--colorArticles);
    height: calc(100% - 7px);
    right: 0;
    bottom: 0;
  }
}

.lineD {
  position: absolute;
  width: 15px;
  left: calc(100% - 43px);
  top: 30px;
  border-bottom: 2px solid var(--colorArticles);
  transform: rotate(45deg);
  transform-origin: center left;
  @media (max-width: 480px) {
    width: 10px;
    border-bottom: 1px solid var(--colorArticles);
    left: calc(100% - 7px);
    top: 0px;
  }
}

.lineE {
  position: absolute;
  width: calc(31% * 1.25 - 7px);
  left: calc(100% - 35px - 31% * 1.25);
  top: 30px;
  border-bottom: 2px solid var(--colorArticles);
  @media (max-width: 480px) {
    width: calc(100% - 14px);
    border-bottom: 1px solid var(--colorArticles);
    left: 7px;
    top: 0px;
  }
}

.lineF {
  position: absolute;
  width: 17px;
  left: calc(100% - 52px - 31% * 1.25);
  top: 30px;
  border-bottom: 2px solid var(--colorArticles);
  transform: rotate(-45deg);
  transform-origin: center right;
  @media (max-width: 480px) {
    width: 10px;
    border-bottom: 1px solid var(--colorArticles);
    left: -2px;
    top: 0px;
  }
}

.lineG {
  position: absolute;
  height: 57px;
  left: calc(100% - 48px - 31% * 1.25);
  bottom: 7px;
  border-right: 2px solid var(--colorArticles);
  @media (max-width: 480px) {
    border-right: 1px solid var(--colorArticles);
    height: calc(100% - 7px);
    left: 0;
    bottom: 0;
  }
}
