@import '../../../scss/animations';

$btnWidth: 35px;
$btnHeight: 145px;
.buttonM {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  pointer-events: auto;
  background-color: var(--colorArticlesB);
  width: $btnWidth;
  height: $btnHeight;
  padding-top: 5px;
  cursor: pointer;
  text-decoration: none;
  // margin-bottom: 20px;
  color: var(--white);
  transition: color 0.15s ease-out, transform 0.2s ease-out;
  border: 1px solid var(--colorArticles);

  backdrop-filter: blur(2px);

  // z-index: 1;
  & p {
    font-family: var(--font-headers);
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: -3px;
    writing-mode: vertical-rl;
    text-orientation: upright;
    text-shadow: -3px 4px 3px var(--colorArticlesD);
  }

  &:hover {
    animation-iteration-count: 1;
    animation-duration: 0.5s;
    animation-name: btnBlinkB;
    transform: translate(-6px, 4px);
  }
}

.buttonWrapperB {
  // border: 1px solid red;
  position: absolute;
  bottom: 0px;
  width: 100%;
  // height: 100%;
}
.buttonWrapper {
  // border: 1px solid red;
  position: relative;
}

.new {
  @extend .buttonM;
  left: 15px;
  top: 7px;
  clip-path: polygon(100% 0, 100% 75%, 40% 100%, 0 100%, 0 0);
}
.copy {
  @extend .new;
  backdrop-filter: blur(0px);
  // z-index: -1;
  left: 8px;
  top: 12px;
  border: none;
  background-color: var(--colorArticlesE);
  transition: all 0.2s ease-out;
  clip-path: polygon(100% 0, 100% 75%, 40% 100%, 0 100%, 0 0);
  &:hover {
    animation-iteration-count: none;
    animation-duration: none;
    animation-name: none;
    transform: none;
  }
}

.copyA {
  @extend .copy;
  left: calc(100vw - (8px + 35px));
  clip-path: polygon(100% 0, 100% 100%, 60% 100%, 0 75%, 0 0);
}

.copyB {
  @extend .copy;
  left: 8px;
  top: -157px;
  clip-path: polygon(40% 0, 100% 25%, 100% 100%, 0 100%, 0 0);
}

.copyC {
  @extend .copy;
  left: calc(100vw - (8px + 35px));
  top: -157px;
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 25%, 40% 0);
}

.glow {
  z-index: -1;
  position: absolute;
  // left: 10px;
  // top: 10px;
  filter: drop-shadow(-5px 5px 2px var(--colorArticlesF));
  transition: all 0.25s ease-out;
}

.buttonM:hover + .glow {
  filter: drop-shadow(-3px 3px 2px var(--colorArticlesD));
}
.buttonM.art:hover {
  transform: translate(-6px, 4px);
}

.glowA {
  @extend .glow;
  filter: drop-shadow(5px 5px 2px var(--colorArticlesF));
}

.glowB {
  @extend .glow;
  filter: drop-shadow(-5px -5px 2px var(--colorArticlesF));
}

.glowC {
  @extend .glow;
  filter: drop-shadow(5px -5px 2px var(--colorArticlesF));
}

.art {
  @extend .buttonM;
  right: 15px;
  top: 7px;
  clip-path: polygon(100% 0, 100% 100%, 60% 100%, 0 75%, 0 0);
}
.buttonM.art:hover {
  transform: translate(6px, 4px);
}

.buttonM.art:hover + .glow {
  filter: drop-shadow(3px 3px 2px var(--colorArticlesD));
}

.lud {
  @extend .buttonM;
  // transform-origin: top center;
  align-items: center;
  left: 15px;
  bottom: 7px;
  // transform: translate(0, -50%);
  clip-path: polygon(40% 0, 100% 25%, 100% 100%, 0 100%, 0 0);
}

.buttonM.lud:hover {
  transform: translate(-6px, -4px);
}

.buttonM.lud:hover + .glow {
  filter: drop-shadow(-3px -3px 2px var(--colorArticlesD));
}

.nft {
  @extend .buttonM;
  align-items: center;
  right: 15px;
  bottom: 7px;
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 25%, 40% 0);
}

.buttonM.nft:hover {
  transform: translate(6px, -4px);
}

.buttonM.nft:hover + .glow {
  filter: drop-shadow(3px -3px 2px var(--colorArticlesD));
}
