.wrapper {
  height: 85px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 7px;
  width: 40%;
  height: 55px;
  left: calc(66px + 38.75%);
}
@media (max-width: 1280px) {
  .wrapper {
    row-gap: 50px;
    align-items: flex-start;
  }
}

.name {
  display: block;
  color: var(--white);
  margin: 5px;
  font-size: 1.8rem;
}
@media (max-width: 1280px) {
  .name {
    width: 1.4rem;
  }
}

.email {
  display: block;
  color: var(--white);
  margin: 5px;
  font-size: 1.2rem;
}

.picWrapper {
  width: 42px;
  height: 42px;
  margin: 5px;
}
.picWrapper img {
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=Profile.module.css.map */