$width: calc(var(--cardWidth) / 5);
$height: calc(var(--cardHeight) / 4.7);

.date {
  font-weight: 100;
  position: absolute;
  top: 5%;
  right: 5%;
  font-family: var(--font-headers);
  // transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  width: $width;
  height: $height;
  border: 2px solid rgba(255, 255, 255, 0.85);
  background-color: var(--blackB);
  color: var(--white);
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  box-shadow: 11px 11px 10px 2px rgba(0, 0, 0, 0.514);
}

.details {
  @extend.date;

  @media (max-width: 480px) {
    margin: 20px;
    padding: 0;
    position: relative;
  }
}

.month {
  font-size: 1.2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (max-width: 480px) {
    font-size: 1rem;
  }
}

.year {
  letter-spacing: 1px;
  font-size: 1.4rem;
  @media (max-width: 480px) {
    font-size: 1rem;
  }
}

.day {
  letter-spacing: 1px;
  font-size: 1.8rem;
  font-weight: bold;
  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
}
