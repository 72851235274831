@media (max-width: 480px) {
  .decortwo, .decortwo4, .decortwo3, .decortwo2 {
    position: absolute;
    left: 0;
    top: 2px;
    right: calc(100% - (10% + 24px));
    bottom: 8px;
    background-image: url(/src/assets/icons/decor2.svg);
    background-position: center;
    background-repeat: repeat-x;
    background-size: cover;
    opacity: 0.2;
  }
}

.decortwo2 {
  right: 0;
  left: calc(100% - (10% + 29px));
  width: calc(10% + 29px);
}

.decortwo3 {
  bottom: 2px;
  top: 8px;
  right: 0;
  left: calc(100% - (10% + 24px));
  width: calc(10% + 29px);
}

.decortwo4 {
  top: 8px;
  bottom: 2px;
  left: 0;
  right: calc(100% - (10% + 29px));
  width: calc(10% + 29px);
}/*# sourceMappingURL=DecorTwo.module.css.map */