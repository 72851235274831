.wrapperAll {
  position: relative;
  height: calc(var(--inputHeight) * 5.5);
  width: calc(var(--inputWidth) + 64px);
  // scrollbar-color: var(--colorArticlesF) var(--colorArticlesA);
  // scrollbar-width: thin;

  // border: 1px solid whitesmoke;
}

.backWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  filter: drop-shadow(-5px 5px 8px var(--colorArticlesB));
}

.background {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  background-color: var(--colorArticles);
  // clip-path: polygon(0% 40%, 10% 0%, 100% 0%, 100% 60%, 90% 100%, 0 100%);
  clip-path: polygon(0% 10%, 9% 0%, 100% 0%, 100% 90%, 91% 100%, 0 100%);
}

.textarea {
  font-family: var(--font-headers);
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: var(--bold);
  // text-transform: uppercase;
  scrollbar-color: var(--colorArticlesF) var(--colorArticlesA);
  scrollbar-width: thin;
  resize: none;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  padding: 15px 30px;
  clip-path: polygon(0% 10%, 9% 0%, 100% 0%, 100% 90%, 91% 100%, 0 100%);
  border: none;
  appearance: none;
  outline: none;
  background-color: var(--colorArticlesDarkB);
  letter-spacing: 1px;

  &::placeholder {
    font-weight: var(--bold);
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--colorGrey100);
    line-height: 1.2rem;
  }

  &:focus {
    background-color: var(--colorGrey100);
  }
}

.textarea::-webkit-scrollbar {
  width: 0.5em;
}
.textarea::-webkit-scrollbar-track {
  border: 1px solid var(--colorArticlesF);
  background-color: var(--colorArticlesA);
}
.textarea::-webkit-scrollbar-thumb {
  border: 1px solid var(--colorArticlesA);
  background-color: var(--colorArticlesF);
}

.comment {
  @extend .textarea;
  clip-path: polygon(0 0, 98% 0, 100% 15%, 100% 85%, 98% 100%, 0 100%);
}

.commentWrap {
  @extend .wrapperAll;
  height: 100%;
  width: 100%;
}

.commentBgk {
  @extend .background;
  clip-path: polygon(0 0, 98% 0, 100% 15%, 100% 85%, 98% 100%, 0 100%);
}
