.wrapperAll, .commentWrap {
  position: relative;
  height: calc(var(--inputHeight) * 5.5);
  width: calc(var(--inputWidth) + 64px);
}

.backWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  filter: drop-shadow(-5px 5px 8px var(--colorArticlesB));
}

.background, .commentBgk {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  background-color: var(--colorArticles);
  -webkit-clip-path: polygon(0% 10%, 9% 0%, 100% 0%, 100% 90%, 91% 100%, 0 100%);
          clip-path: polygon(0% 10%, 9% 0%, 100% 0%, 100% 90%, 91% 100%, 0 100%);
}

.textarea, .comment {
  font-family: var(--font-headers);
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: var(--bold);
  scrollbar-color: var(--colorArticlesF) var(--colorArticlesA);
  scrollbar-width: thin;
  resize: none;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  padding: 15px 30px;
  -webkit-clip-path: polygon(0% 10%, 9% 0%, 100% 0%, 100% 90%, 91% 100%, 0 100%);
          clip-path: polygon(0% 10%, 9% 0%, 100% 0%, 100% 90%, 91% 100%, 0 100%);
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  background-color: var(--colorArticlesDarkB);
  letter-spacing: 1px;
}
.textarea::-moz-placeholder, .comment::-moz-placeholder {
  font-weight: var(--bold);
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--colorGrey100);
  line-height: 1.2rem;
}
.textarea::placeholder, .comment::placeholder {
  font-weight: var(--bold);
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--colorGrey100);
  line-height: 1.2rem;
}
.textarea:focus, .comment:focus {
  background-color: var(--colorGrey100);
}

.textarea::-webkit-scrollbar, .comment::-webkit-scrollbar {
  width: 0.5em;
}

.textarea::-webkit-scrollbar-track, .comment::-webkit-scrollbar-track {
  border: 1px solid var(--colorArticlesF);
  background-color: var(--colorArticlesA);
}

.textarea::-webkit-scrollbar-thumb, .comment::-webkit-scrollbar-thumb {
  border: 1px solid var(--colorArticlesA);
  background-color: var(--colorArticlesF);
}

.comment {
  -webkit-clip-path: polygon(0 0, 98% 0, 100% 15%, 100% 85%, 98% 100%, 0 100%);
          clip-path: polygon(0 0, 98% 0, 100% 15%, 100% 85%, 98% 100%, 0 100%);
}

.commentWrap {
  height: 100%;
  width: 100%;
}

.commentBgk {
  -webkit-clip-path: polygon(0 0, 98% 0, 100% 15%, 100% 85%, 98% 100%, 0 100%);
          clip-path: polygon(0 0, 98% 0, 100% 15%, 100% 85%, 98% 100%, 0 100%);
}/*# sourceMappingURL=Textarea.module.css.map */