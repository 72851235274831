.zoneMiddle {
  display: block;
  width: calc(100% - 40px);
  height: calc(100% - (var(--inputHeight) + 62px + 10vh + 62px));
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  top: calc(var(--inputHeight) + 62px);
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--colorArticlesEB);
  border: 1px solid var(--colorArticles);
  overflow-y: auto;
  scrollbar-color: var(--colorArticlesF) var(--colorArticlesA);
  font-weight: 500;
}
@media (max-width: 1366px) {
  .zoneMiddle {
    top: calc(var(--inputHeight) + 52px);
    height: calc(100% - (var(--inputHeight) + 32px + 10vh + 52px));
    font-size: var(--fontSizeS);
  }
}
.zoneMiddle::-webkit-scrollbar {
  width: 1em;
}

.zoneMiddle::-webkit-scrollbar-track {
  border: 1px solid var(--colorArticlesF);
  background-color: var(--colorArticlesA);
}

.zoneMiddle::-webkit-scrollbar-thumb {
  border: 1px solid var(--colorArticlesA);
  background-color: var(--colorArticlesF);
}/*# sourceMappingURL=ZoneMiddle.module.css.map */