@import '../../../scss/animations';
.wrapperHeader {
  margin-top: 10px;
  margin-bottom: 40px;
  position: absolute;
  top: 0;
  left: 50%;
  width: 90%;
  height: 107px;
  transform: translate(-50%, 0%);
  // border: 1px solid yellowgreen;

  @media (max-width: 1600px) {
    width: 82%;
  }

  @media (max-width: 1440px) {
    left: 48%;
  }

  @media (max-width: 1366px) {
    margin-top: 5px;
    transform: translate(-50%, 0%) scale(95%);
  }
  @media (max-width: 1280px) {
    transform: translate(-50%, 0%) scale(100%);
    width: 85%;
    left: 44%;
  }

  @media (max-width: 1024px) {
    transform: translate(-50%, 0%) scaleY(90%);
    top: -4px;
    width: 86%;
    left: 41%;
  }
  @media (max-width: 480px) {
    overflow: hidden;
    transform: translate(-50%, 0%) scaleY(100%);
    left: 50%;
    top: 0;
    width: 100%;
    height: 7%;
    margin-top: 0px;
  }
}

.innerWrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 80%;
  height: 100%;
  // border: 1px solid white;

  @media (max-width: 480px) {
    left: calc(50% - 2px);
    width: calc(100% - (20% + 53px));
  }
}

.line1 {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: calc(100% - 80px);
  border-top: 2px solid var(--black);
  @media (max-width: 480px) {
    display: none;
  }
}

.line2 {
  position: absolute;
  top: 0px;
  right: 19px;
  width: 20px;
  border-top: 2px solid var(--black);
  transform: rotate(45deg);
  transform-origin: top left;
  @media (max-width: 480px) {
    display: none;
  }
}

.line2A {
  position: absolute;
  top: 14px;
  right: -3px;
  width: 20px;
  border-top: 1px solid var(--blackA);
  transform: rotate(-45deg);
  transform-origin: top left;
  @media (max-width: 480px) {
    display: none;
  }
}

.line3 {
  position: absolute;
  top: 14px;
  right: 25px;
  height: 40px;
  border-right: 2px solid var(--black);
  transform-origin: top right;
  @media (max-width: 480px) {
    display: none;
  }
}

.line3A {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 40px;
  border-right: 1px solid var(--blackA);
  transform-origin: top right;
  @media (max-width: 480px) {
    display: none;
  }
}

.line4 {
  position: absolute;
  top: 53px;
  right: 26px;
  width: 20px;
  border-top: 2px solid var(--black);
  transform: rotate(-45deg);
  transform-origin: top right;
  @media (max-width: 480px) {
    display: none;
  }
}

.line4A {
  position: absolute;
  top: 68px;
  right: 1px;
  width: 20px;
  border-top: 1px solid var(--blackA);
  transform: rotate(45deg);
  transform-origin: top right;
  @media (max-width: 480px) {
    display: none;
  }
}

.line5 {
  position: absolute;
  top: 67px;
  left: calc(53px + 31% * 1.25);
  width: calc(100% - 92px - 31% * 1.25);
  border-bottom: 2px solid var(--black);
  @media (max-width: 480px) {
    display: none;
  }
}

.line6 {
  position: absolute;
  top: 67px;
  left: calc(34px + 31% * 1.25);
  width: 20px;
  border-bottom: 2px solid var(--black);
  transform-origin: top right;
  transform: rotate(-45deg);
  @media (max-width: 480px) {
    display: none;
  }
}

.line6A {
  position: absolute;
  bottom: -2px;
  left: calc(34px + 31% * 1.25);
  width: 20px;
  border-bottom: 1px solid var(--blackA);
  transform-origin: top right;
  transform: rotate(45deg);
  @media (max-width: 1366px) {
    display: none;
  }
  @media (max-width: 480px) {
    display: none;
  }
}

.line7 {
  position: absolute;
  top: 81px;
  left: 41px;
  width: calc(31% * 1.25);
  transform-origin: top right;
  border-bottom: 2px solid var(--black);
  @media (max-width: 480px) {
    display: none;
  }
}

.line7A {
  position: absolute;
  bottom: 12px;
  left: 40px;
  width: calc(31% * 1.25);
  transform-origin: top right;
  border-bottom: 1px solid var(--blackA);
  @media (max-width: 1366px) {
    display: none;
  }
  @media (max-width: 480px) {
    display: none;
  }
}

.line8 {
  position: absolute;
  top: 67px;
  left: 27px;
  width: 20px;
  border-bottom: 2px solid var(--black);
  transform: rotate(45deg);
  transform-origin: bottom left;
  @media (max-width: 480px) {
    display: none;
  }
}

.line8A {
  position: absolute;
  bottom: -2px;
  left: 27px;
  width: 20px;
  border-bottom: 1px solid var(--blackA);
  transform: rotate(-45deg);
  transform-origin: bottom left;
  @media (max-width: 1366px) {
    display: none;
  }
  @media (max-width: 480px) {
    display: none;
  }
}

.line8B {
  position: absolute;
  bottom: 24px;
  left: 3px;
  width: 20px;
  border-bottom: 1px solid var(--blackA);
  transform: rotate(-45deg);
  transform-origin: bottom left;
  @media (max-width: 480px) {
    display: none;
  }
}

.line9 {
  position: absolute;
  top: 14px;
  left: 27px;
  height: 55px;
  border-left: 2px solid var(--black);
  @media (max-width: 480px) {
    display: none;
  }
}

.line9A {
  position: absolute;
  top: 14px;
  left: 16px;
  height: 55px;
  border-left: 1px solid var(--blackA);
  @media (max-width: 480px) {
    display: none;
  }
}

.line10 {
  position: absolute;
  top: 14px;
  left: 28px;
  width: 20px;
  border-bottom: 2px solid var(--black);
  transform: rotate(-45deg);
  transform-origin: bottom left;
  @media (max-width: 480px) {
    display: none;
  }
}

.line10A {
  position: absolute;
  top: -1px;
  left: 2px;
  width: 20px;
  border-bottom: 1px solid var(--blackA);
  transform: rotate(45deg);
  transform-origin: bottom left;
  @media (max-width: 480px) {
    display: none;
  }
}

.lineA {
  position: absolute;
  width: 15px;
  left: 35px;
  bottom: 41px;
  border-bottom: 2px solid var(--colorArticles);
  transform: rotate(45deg);
  transform-origin: center left;

  @media (max-width: 480px) {
    border-bottom: 1px solid var(--colorArticles);
    width: 10px;
    transform: translate(0, -100%) rotate(45deg);
    bottom: 5px;
    left: 1px;
  }
}

.lineB {
  position: absolute;
  width: calc(31% * 1.25 - 8px);
  left: 45px;
  bottom: 31px;
  border-bottom: 2px solid var(--colorArticles);
  @media (max-width: 480px) {
    border-bottom: 1px solid var(--colorArticles);
    left: 7px;
    width: calc(100% - 14px);
    bottom: 0px;
  }
}

.lineC {
  position: absolute;
  width: 17px;
  left: calc(31% * 1.25 + 48px);
  bottom: 42px;
  border-bottom: 2px solid var(--colorArticles);
  transform: rotate(135deg);
  transform-origin: center left;
  @media (max-width: 480px) {
    border-bottom: 1px solid var(--colorArticles);
    left: calc(100% - 7px);
    transform: translate(0, -100%) rotate(-45deg);
    width: 10px;
    bottom: -2px;
  }
}

.lineD {
  position: absolute;
  height: 57px;
  left: calc(31% * 1.25 + 47px);
  top: 7px;
  border-right: 2px solid var(--colorArticles);
  transform-origin: center right;
  @media (max-width: 480px) {
    border-right: 1px solid var(--colorArticles);
    height: calc(100% - 7px);
    left: 0;
    top: 0;
  }
}

.lineE {
  position: absolute;
  left: 46px;
  width: calc(31% * 1.25 + 1px);
  top: 7px;
  border-bottom: 2px solid var(--colorArticles);
  @media (max-width: 480px) {
    border-bottom: 1px solid var(--colorArticles);
    width: 100%;
    left: 0px;
    top: 0px;
  }
}

.lineF {
  position: absolute;
  width: 16px;
  left: 31px;
  top: 6px;
  border-bottom: 2px solid var(--colorArticles);
  transform: rotate(-45deg);
  transform-origin: center right;
  @media (max-width: 480px) {
    display: none;
  }
}

.lineG {
  position: absolute;
  height: 48px;
  left: 35px;
  top: 18px;
  border-right: 2px solid var(--colorArticles);
  @media (max-width: 480px) {
    border-right: 1px solid var(--colorArticles);
    height: calc(100% - 7px);
    left: calc(100% - 1px);
    top: 0;
  }
}

.stat {
  color: white;
}

.bgkWrap {
  position: absolute;
  width: calc(31% * 1.25 + 14px);
  height: 70px;
  left: 35px;
  top: 6px;
  clip-path: polygon(0 20%, 3% 0, 100% 0, 100% 80%, 97% 100%, 2% 100%, 0 80%);
  overflow: hidden;
  // border: 1px solid lightgreen;

  @media (min-width: 1921px) {
    clip-path: polygon(0 20%, 2% 0, 100% 0, 100% 80%, 98% 100%, 2% 100%, 0 80%);
  }

  @media (max-width: 1280px) {
    clip-path: polygon(0 20%, 2.8% 0, 100% 0, 100% 80%, 96.2% 100%, 3% 100%, 0 80%);
  }

  @media (max-width: 1024px) {
    clip-path: polygon(0 20%, 3.7% 0, 100% 0, 100% 80%, 95.8% 100%, 3% 100%, 0 80%);
  }

  @media (max-width: 480px) {
    clip-path: polygon(0 20%, 0 0, 100% 0, 100% 80%, 98% 100%, 2% 100%, 0 80%);
    width: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;
  }
}

.scanline {
  width: 100%;
  height: 35%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    var(--colorArticlesA) 10%,
    rgba(49, 0, 0, 0.1) 100%
  );
  opacity: 0.55;
  position: absolute;
  bottom: 100%;
  transform: translateY(0);
  will-change: transform;
  animation: scanline 7s linear infinite;
}

.background {
  position: absolute;
  background-color: var(--colorArticlesB);
  width: 100%;
  height: 100%;
}

.lines {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(24, 0, 0, 0) 50%, var(--colorArticlesB) 50%);
  background-size: 100% 4px;
  opacity: 0.4;
}
