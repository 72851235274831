*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus {
  outline: 0;
}

button:focus {
  outline: 0;
}

textarea:focus {
  outline: 0;
}
