@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Libre+Barcode+39&family=Montserrat:wght@300;600&family=Press+Start+2P&display=swap');
:root {
  --cardWidth: 440px;
  --cardHeight: 360px;
  --btnloginWidth: 150px;
  --buttonWidth: 210px;
  --buttonWidthSec: 120px;
  --buttonHeight: 40px;
  --buttonHeightSec: 30px;
  --inputHeight: 40px;
  --inputWidth: 240px;

  --colorRadio: rebeccapurple;
  --colorNews: #2bd8ff;
  --colorNewsDark: #0060df;
  --colorPeople: #ff2bd8;
  --colorPeopleDark: #ce132c;
  --colorArticles: #2b87ff;
  --colorArticlesA: #2b64ff25;
  --colorArticlesB: #2b6eff4f;
  --colorArticlesC: #00a2ffa9;
  --colorArticlesD: #57bcff9c;
  --colorArticlesE: #0a45887e;
  --colorArticlesEB: #0b44859c;
  --colorArticlesF: #0061cfd8;
  --colorArticlesG: #4fbeffce;
  --colorArticlesDark: #0026ff;
  --colorArticlesDarkB: #2e2f3f;
  --colorArticlesDarkBA: #2e2f3fd0;
  --color-light-grey: #8d8d8d;
  --colorMainGrey: #373737;
  --colorGrey100: hsl(0, 0%, 75%);
  --colorGrey200: hsl(0, 0%, 60%);
  --colorGrey300: hsl(0, 0%, 50%);
  --colorGrey400: hsl(0, 0%, 30%);

  --colorBgkL: rgba(98, 97, 89, 1);
  --colorBgkD: rgba(59, 58, 52, 1);
  --colorBgkVD: rgb(45, 44, 37);
  --black: rgb(80, 80, 80); //to nie lepiej grey
  --blackA: rgba(80, 80, 80, 0.6);
  --blackB: rgb(32, 32, 32);
  --white: rgba(255, 255, 255, 0.959);
  --colorSocial: rgb(5, 5, 5);

  --fontSizeXXS: 1rem;
  --fontSizeXS: 1.2rem;
  --fontSizeS: 1.6rem;
  --fontSizeM: 2.1rem;
  --fontSizeL: 2.4rem;
  --fontSizeXL: 4rem;
  --light: 300;
  --bold: 600;

  --font-main: 'Montserrat', sans-serif;
  --font-headers: 'Audiowide', cursive;
  // --font-code: 'Libre Barcode 39', cursive;
  --font-pixel: 'Press Start 2P', cursive;

  @media (max-width: 1600px) {
    --cardWidth: 380px;
    --cardHeight: 308px;
    --inputHeight: 28px;
    --inputWidth: 210px;
    --buttonWidth: 180px;
    --buttonWidthSec: 95px;
    --buttonHeight: 30px;
    --buttonHeightSec: 25px;
  }

  @media (max-width: 1366px) {
    --cardWidth: 360px;
    --cardHeight: 292px;
  }

  @media (max-width: 1024px) {
    --cardWidth: 330px;
    --cardHeight: 268px;
  }

  @media (max-width: 480px) {
    --cardWidth: 300px;
    --cardHeight: 243px;
    --buttonWidthSec: 155px;
    --buttonHeightSec: 28px;
  }
}

.logo1 {
  fill: white;
  transition: 0.2s ease-in;
}

html {
  font-size: 62.5%; // tzw. Happy Remki ale 10 px ustawione tutaj robi z Remkami dokładnie to samo ??? :0
}
