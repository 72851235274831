.ul {
  display: block;
  height: 100%;
  width: 100%;
  // display: flex;
  // margin-top: 10px;
  margin-bottom: 10px;
  list-style-type: none;
}

.comment {
  display: grid;
  margin-top: 10px;
  overflow: hidden;
  grid-template-rows: 100px;
  grid-template-columns: 10% 75% 15%;
  width: 100%;
  border: 1px solid var(--colorArticles);
  // justify-content: center;
  // align-content: center;

  @media (max-width: 1600px) {
    margin-top: 5px;
  }
}

.comment:last-child {
  margin-bottom: 10px;

  @media (max-width: 1600px) {
    margin-bottom: 5px;
  }
}

.nameWrap {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  // height: 100%;
  // width: 100%;
  // border: 1px solid orange;
  justify-self: stretch;
  align-self: stretch;
  // justify-content: center;
  // display: flex;
  // align-content: center;
  word-wrap: break-word;
}

.pic {
  height: 40px;
  width: 40px;
}
.name {
  // justify-self: stretch;
  // align-self: stretch;
  // justify-self: center;
  // align-self: center;
  font-family: var(--font-headers);
  display: block;
  height: 100%;
  text-align: center;
  margin: auto;
  // line-height: 2.1rem;
  color: var(--white);
  font-size: 1.5rem;
  // font-weight: 700;
  border-right: 1px solid var(--colorArticles);
}
.date {
  height: 20px;
  font-family: var(--font-headers);
  display: block;
  color: var(--white);
  font-size: 1.4rem;
  // border: 1px solid red;
}

.contentWrap {
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  // border-left: 1px solid var(--white);
  @media (max-width: 1600px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media (max-width: 1366px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.contentBgk {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--colorArticlesDarkB);
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  clip-path: polygon(0 25%, 2% 0, 100% 0, 100% 75%, 98% 100%, 0 100%);
}
.contentBgkBorder {
  position: relative;
  background-color: var(--colorArticles);
  width: 100%;
  height: 100%;
  clip-path: polygon(0 25%, 2% 0, 100% 0, 100% 75%, 98% 100%, 0 100%);
}

.content {
  display: block;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  line-height: 2rem;
  color: white;
  // font-family: var(--font-headers);
  text-indent: 20px;
  text-shadow: -3px 3px 3px rgba(20, 20, 20, 0.7);
  font-size: 1.6rem;
  font-weight: 700;
  word-wrap: break-word;

  @media (max-width: 1600px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  @media (max-width: 1366px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.btnWrap {
  // height: 100px;
  // width: 80px;
  // border: 1px solid green;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 5px;
  // align-items: center;
}

.info {
  font-family: var(--font-headers);
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // border: 1px solid red;
}
