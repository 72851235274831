@keyframes btnBlink {
  0% {
    color: var(--colorArticles);
    border-right: 2px solid var(--colorArticles);
  }
  5% {
    border-right: 2px solid var(--white);
  }
  15% {
    color: var(--white);
  }
  25% {
    border-right: 2px solid var(--white);
    color: var(--white);
  }
  30% {
    border-right: 2px solid var(--colorArticles);
  }
  35% {
    color: var(--colorArticles);
    border-bottom: 2px solid var(--colorArticles);
  }
  40% {
    border-bottom: 2px solid var(--white);
  }
  45% {
    color: var(--colorArticles);
  }
  55% {
    color: black;
  }
  60% {
    border-bottom: 2px solid var(--colorArticles);
  }
  65% {
    border-left: 2px solid var(--colorArticles);
    color: var(--white);
  }
  70% {
    border-left: 2px solid var(--white);
  }
  75% {
    color: black;
  }
  80% {
    border-top: 1px solid var(--colorArticles);
  }
  85% {
    border-top: 1px solid var(--white);
    border-left: 2px solid var(--white);
  }
  90% {
    border-top: 1px solid var(--white);
    border-left: 2px solid var(--colorArticles);
  }
  99% {
    border-top: 1px solid var(--colorArticles);
  }
  100% {
    color: var(--colorArticles);
  }
}
@keyframes btnBlinkL {
  0% {
    color: var(--colorNews);
    border-right: 2px solid var(--colorNews);
  }
  5% {
    border-right: 2px solid var(--black);
  }
  15% {
    color: var(--black);
  }
  25% {
    border-right: 2px solid var(--black);
    color: var(--black);
  }
  30% {
    border-right: 2px solid var(--colorNews);
  }
  35% {
    color: var(--colorNews);
    border-bottom: 2px solid var(--colorNews);
  }
  40% {
    border-bottom: 2px solid var(--black);
  }
  45% {
    color: var(--colorNews);
  }
  55% {
    color: var(--white);
  }
  60% {
    border-bottom: 2px solid var(--colorNews);
  }
  65% {
    border-left: 2px solid var(--colorNews);
    color: var(--black);
  }
  70% {
    border-left: 2px solid var(--black);
  }
  75% {
    color: black;
  }
  80% {
    border-top: 1px solid var(--colorNews);
  }
  85% {
    border-top: 1px solid var(--black);
    border-left: 2px solid var(--black);
  }
  90% {
    border-top: 1px solid var(--black);
    border-left: 2px solid var(--colorNews);
  }
  99% {
    border-top: 1px solid var(--colorNews);
  }
  100% {
    color: var(--colorNews);
  }
}
@keyframes btnBlinkB {
  0% {
    color: var(--colorArticles);
    border-right: 1px solid var(--colorArticles);
  }
  5% {
    border-right: 1px solid var(--white);
  }
  15% {
    color: var(--white);
  }
  25% {
    border-right: 1px solid var(--white);
    color: var(--white);
  }
  30% {
    border-right: 1px solid var(--colorArticles);
  }
  35% {
    color: var(--colorArticles);
    border-bottom: 1px solid var(--colorArticles);
  }
  40% {
    border-bottom: 1px solid var(--white);
  }
  45% {
    color: var(--colorArticles);
  }
  55% {
    color: var(--white);
  }
  60% {
    border-bottom: 1px solid var(--colorArticles);
  }
  65% {
    border-left: 1px solid var(--colorArticles);
    color: var(--white);
  }
  70% {
    border-left: 1px solid var(--white);
  }
  75% {
    color: var(--colorArticles);
  }
  80% {
    border-top: 1px solid var(--colorArticles);
  }
  85% {
    border-top: 1px solid var(--white);
    border-left: 1px solid var(--white);
  }
  90% {
    border-top: 1px solid var(--white);
    border-left: 1px solid var(--colorArticles);
  }
  99% {
    border-top: 1px solid var(--colorArticles);
  }
  100% {
    color: var(--colorArticles);
  }
}
@keyframes glowMovment {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-10px, 4px);
  }
  50% {
    transform: translate(0px, 8px);
  }
  75% {
    transform: translate(5px, 4px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes scanline {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(500%);
  }
}
@keyframes slideKont {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-365px);
  }
}
@keyframes KolorIn {
  0% {
    background-color: var(--colorArticlesA);
  }
  100% {
    background-color: var(--colorSocial);
  }
}
@keyframes hideKont {
  0% {
    transform: translateX(-365px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes KolorOut {
  0% {
    background-color: var(--colorSocial);
  }
  100% {
    background-color: var(--colorArticlesA);
  }
}
@media (max-width: 480px) {
  @keyframes slideKont {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw);
    }
  }
  @keyframes hideKont {
    0% {
      transform: translateX(-100vw);
    }
    100% {
      transform: translateX(0px);
    }
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.button, .menu, .comment, .buttonDetails, .kontakt, .kontaktM, .secondary, .buttonFlex, .login {
  margin: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: right;
  align-items: center;
  color: var(--white);
  text-shadow: -3px 4px 3px var(--colorArticlesD);
  background-color: var(--colorArticlesB);
  width: var(--buttonWidth);
  height: var(--buttonHeight);
  -webkit-clip-path: polygon(90% 0%, 100% 30%, 100% 100%, 10% 100%, 0% 70%, 0 0);
          clip-path: polygon(90% 0%, 100% 30%, 100% 100%, 10% 100%, 0% 70%, 0 0);
  font-family: var(--font-headers);
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 20px;
  transition: color 0.15s ease-out, transform 0.2s ease-out;
  border-left: 2px solid var(--colorArticles);
  border-right: 2px solid var(--colorArticles);
  border-bottom: 2px solid var(--colorArticles);
  border-top: 2px solid var(--colorArticles);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.button:hover, .menu:hover, .comment:hover, .buttonDetails:hover, .kontakt:hover, .kontaktM:hover, .secondary:hover, .buttonFlex:hover, .login:hover {
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  animation-name: btnBlink;
  transform: translate(-3px, 4px);
}

.button:hover + .glow, .menu:hover + .glow, .button:hover + .glowMe, .menu:hover + .glowMe, .button:hover + .glowL, .menu:hover + .glowL, .button:hover + .glowS, .menu:hover + .glowS, .comment:hover + .glow, .comment:hover + .glowMe, .comment:hover + .glowL, .comment:hover + .glowS, .buttonDetails:hover + .glow, .buttonDetails:hover + .glowMe, .buttonDetails:hover + .glowL, .buttonDetails:hover + .glowS, .kontakt:hover + .glow, .kontakt:hover + .glowMe, .kontakt:hover + .glowL, .kontakt:hover + .glowS, .kontaktM:hover + .glow, .kontaktM:hover + .glowMe, .kontaktM:hover + .glowL, .kontaktM:hover + .glowS, .secondary:hover + .glow, .secondary:hover + .glowMe, .secondary:hover + .glowL, .secondary:hover + .glowS, .buttonFlex:hover + .glow, .buttonFlex:hover + .glowMe, .buttonFlex:hover + .glowL, .buttonFlex:hover + .glowS, .login:hover + .glow, .login:hover + .glowMe, .login:hover + .glowL, .login:hover + .glowS {
  filter: drop-shadow(-3px 3px 2px var(--colorArticlesD));
}

.button:hover + .glowComm, .menu:hover + .glowComm, .comment:hover + .glowComm, .buttonDetails:hover + .glowComm, .kontakt:hover + .glowComm, .kontaktM:hover + .glowComm, .secondary:hover + .glowComm, .buttonFlex:hover + .glowComm, .login:hover + .glowComm {
  filter: drop-shadow(-3px 3px 2px var(--colorArticlesD));
}

.kontakt:hover + .glowKont, .kontakt:hover + .glowKontM, .kontaktM:hover + .glowKont, .kontaktM:hover + .glowKontM {
  filter: drop-shadow(3px 3px 2px var(--colorArticlesD));
}

.kontaktM:hover + .glowKontM {
  filter: drop-shadow(3px 3px 2px var(--colorArticlesD));
}

.login {
  width: var(--btnloginWidth);
  border-left: 2px solid var(--white);
  border-right: 2px solid var(--white);
  border-bottom: 2px solid var(--white);
  border-top: 2px solid var(--white);
  background-color: rgba(255, 255, 255, 0.4);
  justify-content: center;
  padding-right: 0;
  text-shadow: -3px 4px 3px var(--black);
}
.login:hover {
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  animation-name: btnBlinkL;
  transform: translate(-3px, 4px);
}

.buttonFlex {
  font-size: 1.2rem;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
}

.secondary {
  position: absolute;
  top: 3px;
  left: 11px;
  margin: 0;
  padding: 0;
  justify-content: center;
  width: calc(var(--buttonWidth) / 1.5);
  height: var(--buttonHeight);
}
.secondary:hover {
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  animation-name: btnBlinkB;
  transform: translate(-3px, 4px);
}
@media (max-width: 1600px) {
  .secondary {
    font-weight: 300;
    font-size: 1.3rem;
  }
}
@media (max-width: 1024px) {
  .secondary {
    justify-content: center;
    margin: 15px;
    font-size: 1.1rem;
  }
}
@media (max-width: 480px) {
  .secondary {
    justify-content: center;
    position: absolute;
    margin: 0px;
    font-size: 1rem;
    width: calc(var(--buttonWidth) / 1.8);
    height: calc(var(--buttonHeight) - 6px);
    left: calc(50% - var(--buttonWidth) / 3.6);
  }
}

.kontakt, .kontaktM {
  width: var(--buttonHeight);
  height: var(--buttonWidth);
  justify-content: center;
  padding-right: 0;
  margin: 0;
  letter-spacing: -2px;
  -webkit-clip-path: polygon(35% 100%, 0 92%, 0 8%, 35% 0, 100% 0, 100% 100%);
          clip-path: polygon(35% 100%, 0 92%, 0 8%, 35% 0, 100% 0, 100% 100%);
}
@media (max-width: 1366px) {
  .kontakt, .kontaktM {
    width: calc(var(--buttonHeight) - 5px);
    height: calc(var(--buttonWidth) - 30px);
    font-size: 1.2rem;
  }
}
.kontakt p, .kontaktM p {
  writing-mode: vertical-rl;
  text-orientation: upright;
}
.kontakt:hover, .kontaktM:hover {
  transform: translate(3px, 4px);
}

.kontaktM {
  display: none;
}
@media (max-width: 480px) {
  .kontaktM {
    justify-content: center;
    display: flex;
  }
}

.buttonDetails {
  position: absolute;
  top: 0px;
  left: 11px;
  margin: 0;
}

.comment {
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
  margin: 0;
  padding-right: 0px;
  padding-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonWrapper {
  position: relative;
}

.buttonLWrapper {
  position: absolute;
  top: 2px;
  right: 0;
  transform: translate(100%, 0);
}
@media (max-width: 480px) {
  .buttonLWrapper {
    right: 50%;
    transform: translate(0, 50%);
  }
}

.buttonSWrapper, .buttonDWrapper {
  width: calc(var(--buttonWidth) / 1.5 + 10px);
  height: calc(var(--buttonHeight) + 10px);
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 1;
}

.buttonDWrapper {
  width: var(--buttonWidth);
  height: var(--buttonHeight);
  bottom: 50px;
  left: 320px;
}
@media (max-width: 480px) {
  .buttonDWrapper {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.buttonFlexWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.copy, .copyComm, .copyMe, .copyS, .copyL {
  width: var(--buttonWidth);
  height: var(--buttonHeight);
  -webkit-clip-path: polygon(90% 0%, 100% 30%, 100% 100%, 10% 100%, 0% 70%, 0 0);
          clip-path: polygon(90% 0%, 100% 30%, 100% 100%, 10% 100%, 0% 70%, 0 0);
  background-color: var(--colorArticlesE);
  transition: all 0.2s ease-out;
}

.copyL {
  width: var(--btnloginWidth);
  position: absolute;
  left: -2px;
  top: 11px;
  background-color: rgba(255, 255, 255, 0.2);
}

.copyS {
  position: absolute;
  left: 0;
  top: 6px;
  background-color: var(--colorArticlesB);
  width: calc(var(--buttonWidth) / 1.5);
}
@media (max-width: 480px) {
  .copyS {
    left: calc(50% - var(--buttonWidth) / 3.6);
    width: calc(var(--buttonWidth) / 1.8);
    height: calc(var(--buttonHeight) - 6px);
  }
}

.copyKont, .copyKontM {
  width: var(--buttonHeight);
  height: var(--buttonWidth);
  -webkit-clip-path: polygon(35% 100%, 0 92%, 0 8%, 35% 0, 100% 0, 100% 100%);
          clip-path: polygon(35% 100%, 0 92%, 0 8%, 35% 0, 100% 0, 100% 100%);
  background-color: var(--colorArticlesB);
  transition: all 0.2s ease-out;
}
@media (max-width: 1366px) {
  .copyKont, .copyKontM {
    width: calc(var(--buttonHeight) - 5px);
    height: calc(var(--buttonWidth) - 30px);
  }
}

.copyKontM {
  display: none;
}
@media (max-width: 480px) {
  .copyKontM {
    display: block;
  }
}

.copyWys {
  width: var(--buttonWidth);
  height: var(--buttonHeight);
  -webkit-clip-path: polygon(90% 0%, 100% 30%, 100% 100%, 10% 100%, 0% 70%, 0 0);
          clip-path: polygon(90% 0%, 100% 30%, 100% 100%, 10% 100%, 0% 70%, 0 0);
  background-color: var(--colorArticlesB);
  transition: all 0.2s ease-out;
}

.copyMe {
  width: 210px;
  height: 40px;
  background-color: var(--colorArticlesE);
}

.copyComm {
  background-color: var(--colorArticlesB);
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
}

.glow, .glowMe, .glowL, .glowS {
  z-index: -1;
  position: absolute;
  left: 5px;
  top: 4px;
  filter: drop-shadow(-6px 6px 2px var(--colorArticlesF));
  transition: all 0.25s ease-out;
}

@media (max-width: 480px) {
  .glowS {
    left: calc(50% - var(--buttonWidth) / 3.6 - 5px);
    width: calc(var(--buttonWidth) / 1.8);
    height: calc(var(--buttonHeight) - 6px);
  }
}

.glowL {
  width: var(--btnloginWidth);
  filter: drop-shadow(-6px 6px 2px rgba(255, 255, 255, 0.4));
}

.glowKont, .glowKontM {
  z-index: -1;
  position: absolute;
  left: 5px;
  top: 4px;
  filter: drop-shadow(6px 6px 2px var(--colorArticlesF));
  transition: all 0.25s ease-out;
}

.glowMe {
  filter: drop-shadow(-6px 6px 2px var(--colorArticlesC));
}

.glowKontM {
  display: none;
}
@media (max-width: 480px) {
  .glowKontM {
    display: block;
  }
}

.glowComm {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: -5px;
  top: 4px;
  filter: drop-shadow(-6px 6px 2px var(--colorArticlesF));
  transition: all 0.25s ease-out;
}

.menu {
  width: 210px;
  height: 40px;
}

.news {
  transform-origin: top left;
  padding: 0;
  margin: 0;
  left: 30px;
  position: absolute;
  transform: rotate(60deg);
}

.art {
  transform-origin: top right;
  padding: 0;
  margin: 0;
  right: 30px;
  position: absolute;
  transform: rotate(-60deg);
}/*# sourceMappingURL=Button.module.css.map */