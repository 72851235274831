@keyframes btnBlink {
  0% {
    color: var(--colorArticles);
    border-right: 2px solid var(--colorArticles);
  }
  5% {
    border-right: 2px solid var(--white);
  }
  15% {
    color: var(--white);
  }
  25% {
    border-right: 2px solid var(--white);
    color: var(--white);
  }
  30% {
    border-right: 2px solid var(--colorArticles);
  }
  35% {
    color: var(--colorArticles);
    border-bottom: 2px solid var(--colorArticles);
  }
  40% {
    border-bottom: 2px solid var(--white);
  }
  45% {
    color: var(--colorArticles);
  }
  55% {
    color: black;
  }
  60% {
    border-bottom: 2px solid var(--colorArticles);
  }
  65% {
    border-left: 2px solid var(--colorArticles);
    color: var(--white);
  }
  70% {
    border-left: 2px solid var(--white);
  }
  75% {
    color: black;
  }
  80% {
    border-top: 1px solid var(--colorArticles);
  }
  85% {
    border-top: 1px solid var(--white);
    border-left: 2px solid var(--white);
  }
  90% {
    border-top: 1px solid var(--white);
    border-left: 2px solid var(--colorArticles);
  }
  99% {
    border-top: 1px solid var(--colorArticles);
  }
  100% {
    color: var(--colorArticles);
  }
}
@keyframes btnBlinkL {
  0% {
    color: var(--colorNews);
    border-right: 2px solid var(--colorNews);
  }
  5% {
    border-right: 2px solid var(--black);
  }
  15% {
    color: var(--black);
  }
  25% {
    border-right: 2px solid var(--black);
    color: var(--black);
  }
  30% {
    border-right: 2px solid var(--colorNews);
  }
  35% {
    color: var(--colorNews);
    border-bottom: 2px solid var(--colorNews);
  }
  40% {
    border-bottom: 2px solid var(--black);
  }
  45% {
    color: var(--colorNews);
  }
  55% {
    color: var(--white);
  }
  60% {
    border-bottom: 2px solid var(--colorNews);
  }
  65% {
    border-left: 2px solid var(--colorNews);
    color: var(--black);
  }
  70% {
    border-left: 2px solid var(--black);
  }
  75% {
    color: black;
  }
  80% {
    border-top: 1px solid var(--colorNews);
  }
  85% {
    border-top: 1px solid var(--black);
    border-left: 2px solid var(--black);
  }
  90% {
    border-top: 1px solid var(--black);
    border-left: 2px solid var(--colorNews);
  }
  99% {
    border-top: 1px solid var(--colorNews);
  }
  100% {
    color: var(--colorNews);
  }
}
@keyframes btnBlinkB {
  0% {
    color: var(--colorArticles);
    border-right: 1px solid var(--colorArticles);
  }
  5% {
    border-right: 1px solid var(--white);
  }
  15% {
    color: var(--white);
  }
  25% {
    border-right: 1px solid var(--white);
    color: var(--white);
  }
  30% {
    border-right: 1px solid var(--colorArticles);
  }
  35% {
    color: var(--colorArticles);
    border-bottom: 1px solid var(--colorArticles);
  }
  40% {
    border-bottom: 1px solid var(--white);
  }
  45% {
    color: var(--colorArticles);
  }
  55% {
    color: var(--white);
  }
  60% {
    border-bottom: 1px solid var(--colorArticles);
  }
  65% {
    border-left: 1px solid var(--colorArticles);
    color: var(--white);
  }
  70% {
    border-left: 1px solid var(--white);
  }
  75% {
    color: var(--colorArticles);
  }
  80% {
    border-top: 1px solid var(--colorArticles);
  }
  85% {
    border-top: 1px solid var(--white);
    border-left: 1px solid var(--white);
  }
  90% {
    border-top: 1px solid var(--white);
    border-left: 1px solid var(--colorArticles);
  }
  99% {
    border-top: 1px solid var(--colorArticles);
  }
  100% {
    color: var(--colorArticles);
  }
}
@keyframes glowMovment {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-10px, 4px);
  }
  50% {
    transform: translate(0px, 8px);
  }
  75% {
    transform: translate(5px, 4px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes scanline {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(500%);
  }
}
@keyframes slideKont {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-365px);
  }
}
@keyframes KolorIn {
  0% {
    background-color: var(--colorArticlesA);
  }
  100% {
    background-color: var(--colorSocial);
  }
}
@keyframes hideKont {
  0% {
    transform: translateX(-365px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes KolorOut {
  0% {
    background-color: var(--colorSocial);
  }
  100% {
    background-color: var(--colorArticlesA);
  }
}
@media (max-width: 480px) {
  @keyframes slideKont {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw);
    }
  }
  @keyframes hideKont {
    0% {
      transform: translateX(-100vw);
    }
    100% {
      transform: translateX(0px);
    }
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.sidebar {
  position: fixed;
  width: 170px;
  height: calc(100vh - 12px);
  left: 6px;
  top: 6px;
  background-image: url("../../../assets/icons/palsterB.svg");
  background-repeat: repeat;
  background-position: 32.2%, 32.2%;
  background-size: 32.2%;
  -webkit-mask-image: url("../../../assets/icons/maska.svg");
          mask-image: url("../../../assets/icons/maska.svg");
  -webkit-mask-repeat: repeat;
          mask-repeat: repeat;
  -webkit-mask-position: 32.2%, 32.2%;
          mask-position: 32.2%, 32.2%;
  -webkit-mask-size: 32.2%;
          mask-size: 32.2%;
  -webkit-clip-path: polygon(0 1.6%, 10% 0, 100% 0, 100% 100%, 10% 100%, 0 98.4%);
          clip-path: polygon(0 1.6%, 10% 0, 100% 0, 100% 100%, 10% 100%, 0 98.4%);
}
@media (max-width: 1366px) {
  .sidebar {
    width: 150px;
  }
}
@media (max-width: 1024px) {
  .sidebar {
    width: 120px;
  }
}
@media (max-width: 480px) {
  .sidebar {
    display: none;
  }
}

.background {
  position: fixed;
  width: 176px;
  height: 100vh;
  left: 0;
  top: 0;
  background: #061a41;
}
@media (max-width: 1366px) {
  .background {
    width: 156px;
  }
}
@media (max-width: 1024px) {
  .background {
    width: 126px;
  }
}
@media (max-width: 480px) {
  .background {
    display: none;
  }
}

.wrapper {
  position: fixed;
  width: 170px;
  height: 100vh;
  left: 0;
  top: 0;
}
@media (max-width: 1366px) {
  .wrapper {
    width: 150px;
  }
}
@media (max-width: 1024px) {
  .wrapper {
    width: 120px;
  }
}
@media (max-width: 480px) {
  .wrapper {
    display: none;
  }
}

.glowWrapper {
  position: absolute;
  top: 49%;
  transform: translate(0, -50%);
  left: 0;
  width: 170px;
  height: 250px;
}
@media (max-width: 1600px) {
  .glowWrapper {
    height: 225px;
  }
}
@media (max-width: 1366px) {
  .glowWrapper {
    height: 210px;
    width: 150px;
    top: 50%;
  }
}
@media (max-width: 1024px) {
  .glowWrapper {
    height: 190px;
    width: 126px;
  }
}
@media (max-width: 480px) {
  .glowWrapper {
    display: none;
  }
}

.glow {
  position: absolute;
  top: 0%;
  transform: translateY(0);
  left: 5px;
  height: 65px;
  width: 165px;
  background: radial-gradient(closest-side, rgb(227, 248, 255) 30%, var(--colorArticlesD) 55%, rgba(0, 183, 255, 0.5215686275) 75%, rgba(0, 0, 0, 0) 95%);
  transition: top 0.4s ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-name: glowMovment;
}
@media (max-width: 1600px) {
  .glow {
    height: 63px;
  }
}
@media (max-width: 1366px) {
  .glow {
    width: 160px;
    height: 52px;
  }
}
@media (max-width: 1024px) {
  .glow {
    width: 120px;
  }
}
@media (max-width: 480px) {
  .glow {
    display: none;
  }
}

.animeA {
  top: 0%;
}

.animeB {
  top: 25%;
}

.animeC {
  top: 50%;
}

.animeD {
  top: 75%;
}

.ulistM {
  position: absolute;
}/*# sourceMappingURL=SideBar.css.map */