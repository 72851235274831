@keyframes btnBlink {
  0% {
    color: var(--colorArticles);
    border-right: 2px solid var(--colorArticles);
  }
  5% {
    border-right: 2px solid var(--white);
  }
  15% {
    color: var(--white);
  }
  25% {
    border-right: 2px solid var(--white);
    color: var(--white);
  }
  30% {
    border-right: 2px solid var(--colorArticles);
  }
  35% {
    color: var(--colorArticles);
    border-bottom: 2px solid var(--colorArticles);
  }
  40% {
    border-bottom: 2px solid var(--white);
  }
  45% {
    color: var(--colorArticles);
  }
  55% {
    color: black;
  }
  60% {
    border-bottom: 2px solid var(--colorArticles);
  }
  65% {
    border-left: 2px solid var(--colorArticles);
    color: var(--white);
  }
  70% {
    border-left: 2px solid var(--white);
  }
  75% {
    color: black;
  }
  80% {
    border-top: 1px solid var(--colorArticles);
  }
  85% {
    border-top: 1px solid var(--white);
    border-left: 2px solid var(--white);
  }
  90% {
    border-top: 1px solid var(--white);
    border-left: 2px solid var(--colorArticles);
  }
  99% {
    border-top: 1px solid var(--colorArticles);
  }
  100% {
    color: var(--colorArticles);
  }
}
@keyframes btnBlinkL {
  0% {
    color: var(--colorNews);
    border-right: 2px solid var(--colorNews);
  }
  5% {
    border-right: 2px solid var(--black);
  }
  15% {
    color: var(--black);
  }
  25% {
    border-right: 2px solid var(--black);
    color: var(--black);
  }
  30% {
    border-right: 2px solid var(--colorNews);
  }
  35% {
    color: var(--colorNews);
    border-bottom: 2px solid var(--colorNews);
  }
  40% {
    border-bottom: 2px solid var(--black);
  }
  45% {
    color: var(--colorNews);
  }
  55% {
    color: var(--white);
  }
  60% {
    border-bottom: 2px solid var(--colorNews);
  }
  65% {
    border-left: 2px solid var(--colorNews);
    color: var(--black);
  }
  70% {
    border-left: 2px solid var(--black);
  }
  75% {
    color: black;
  }
  80% {
    border-top: 1px solid var(--colorNews);
  }
  85% {
    border-top: 1px solid var(--black);
    border-left: 2px solid var(--black);
  }
  90% {
    border-top: 1px solid var(--black);
    border-left: 2px solid var(--colorNews);
  }
  99% {
    border-top: 1px solid var(--colorNews);
  }
  100% {
    color: var(--colorNews);
  }
}
@keyframes btnBlinkB {
  0% {
    color: var(--colorArticles);
    border-right: 1px solid var(--colorArticles);
  }
  5% {
    border-right: 1px solid var(--white);
  }
  15% {
    color: var(--white);
  }
  25% {
    border-right: 1px solid var(--white);
    color: var(--white);
  }
  30% {
    border-right: 1px solid var(--colorArticles);
  }
  35% {
    color: var(--colorArticles);
    border-bottom: 1px solid var(--colorArticles);
  }
  40% {
    border-bottom: 1px solid var(--white);
  }
  45% {
    color: var(--colorArticles);
  }
  55% {
    color: var(--white);
  }
  60% {
    border-bottom: 1px solid var(--colorArticles);
  }
  65% {
    border-left: 1px solid var(--colorArticles);
    color: var(--white);
  }
  70% {
    border-left: 1px solid var(--white);
  }
  75% {
    color: var(--colorArticles);
  }
  80% {
    border-top: 1px solid var(--colorArticles);
  }
  85% {
    border-top: 1px solid var(--white);
    border-left: 1px solid var(--white);
  }
  90% {
    border-top: 1px solid var(--white);
    border-left: 1px solid var(--colorArticles);
  }
  99% {
    border-top: 1px solid var(--colorArticles);
  }
  100% {
    color: var(--colorArticles);
  }
}
@keyframes glowMovment {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-10px, 4px);
  }
  50% {
    transform: translate(0px, 8px);
  }
  75% {
    transform: translate(5px, 4px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes scanline {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(500%);
  }
}
@keyframes slideKont {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-365px);
  }
}
@keyframes KolorIn {
  0% {
    background-color: var(--colorArticlesA);
  }
  100% {
    background-color: var(--colorSocial);
  }
}
@keyframes hideKont {
  0% {
    transform: translateX(-365px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes KolorOut {
  0% {
    background-color: var(--colorSocial);
  }
  100% {
    background-color: var(--colorArticlesA);
  }
}
@media (max-width: 480px) {
  @keyframes slideKont {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw);
    }
  }
  @keyframes hideKont {
    0% {
      transform: translateX(-100vw);
    }
    100% {
      transform: translateX(0px);
    }
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.contactWrapper {
  height: 100%;
  width: 385px;
  position: absolute;
  top: 0;
  right: -365px;
  z-index: 1;
  pointer-events: auto;
}
@media (max-width: 480px) {
  .contactWrapper {
    width: 100vw;
    right: -100vw;
  }
}

.bgkWrap {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(0 17.5%, 5.5% 15%, 25.5% 15%, 31.5% 12.5%, 31.5% 2%, 37% 0, 100% 0, 100% 100%, 37% 100%, 31.5% 98%, 31.5% 87%, 26.5% 85%, 5.5% 85%, 0 82.5%);
          clip-path: polygon(0 17.5%, 5.5% 15%, 25.5% 15%, 31.5% 12.5%, 31.5% 2%, 37% 0, 100% 0, 100% 100%, 37% 100%, 31.5% 98%, 31.5% 87%, 26.5% 85%, 5.5% 85%, 0 82.5%);
}
@media (min-width: 1921px) {
  .bgkWrap {
    -webkit-clip-path: polygon(0 16.5%, 5.5% 15%, 27% 15%, 32% 13%, 31% 2%, 37% 0, 100% 0, 100% 100%, 37% 100%, 31% 98%, 32% 87%, 27% 85%, 5.5% 85%, 0 83.5%);
            clip-path: polygon(0 16.5%, 5.5% 15%, 27% 15%, 32% 13%, 31% 2%, 37% 0, 100% 0, 100% 100%, 37% 100%, 31% 98%, 32% 87%, 27% 85%, 5.5% 85%, 0 83.5%);
  }
}
@media (min-width: 1921px) and (max-height: 1153px) {
  .bgkWrap {
    -webkit-clip-path: polygon(0 16.9%, 5.5% 15%, 27% 15%, 32% 13%, 31% 2%, 37% 0, 100% 0, 100% 100%, 37% 100%, 31% 98%, 32% 87%, 27% 85%, 5.5% 85%, 0 83.1%);
            clip-path: polygon(0 16.9%, 5.5% 15%, 27% 15%, 32% 13%, 31% 2%, 37% 0, 100% 0, 100% 100%, 37% 100%, 31% 98%, 32% 87%, 27% 85%, 5.5% 85%, 0 83.1%);
  }
}
@media (max-width: 1920px) and (min-height: 1080px) {
  .bgkWrap {
    -webkit-clip-path: polygon(0 16.9%, 5.5% 15%, 27% 15%, 32% 13%, 31% 2%, 37% 0, 100% 0, 100% 100%, 37% 100%, 31% 98%, 32% 87%, 27% 85%, 5.5% 85%, 0 83.1%);
            clip-path: polygon(0 16.9%, 5.5% 15%, 27% 15%, 32% 13%, 31% 2%, 37% 0, 100% 0, 100% 100%, 37% 100%, 31% 98%, 32% 87%, 27% 85%, 5.5% 85%, 0 83.1%);
  }
}
@media (max-width: 1600px) {
  .bgkWrap {
    -webkit-clip-path: polygon(0 18%, 5.5% 15%, 27% 15%, 31% 13%, 32% 2.5%, 37% 0, 100% 0, 100% 100%, 37% 100%, 32% 97.5%, 31% 87%, 27% 85%, 5.5% 85%, 0 82%);
            clip-path: polygon(0 18%, 5.5% 15%, 27% 15%, 31% 13%, 32% 2.5%, 37% 0, 100% 0, 100% 100%, 37% 100%, 32% 97.5%, 31% 87%, 27% 85%, 5.5% 85%, 0 82%);
  }
}
@media (max-width: 1366px) {
  .bgkWrap {
    -webkit-clip-path: polygon(0 18.3%, 5.5% 15%, 27% 15%, 31% 12%, 32% 2.8%, 37% 0, 100% 0, 100% 100%, 37% 100%, 32% 97.2%, 31% 88%, 27% 85%, 5.5% 85%, 0 81.7%);
            clip-path: polygon(0 18.3%, 5.5% 15%, 27% 15%, 31% 12%, 32% 2.8%, 37% 0, 100% 0, 100% 100%, 37% 100%, 32% 97.2%, 31% 88%, 27% 85%, 5.5% 85%, 0 81.7%);
  }
}
@media (max-width: 1280px) {
  .bgkWrap {
    -webkit-clip-path: polygon(0 18%, 5.5% 15%, 27% 15%, 31% 13%, 32% 2.5%, 37% 0, 100% 0, 100% 100%, 37% 100%, 32% 97.5%, 31% 87%, 27% 85%, 5.5% 85%, 0 82%);
            clip-path: polygon(0 18%, 5.5% 15%, 27% 15%, 31% 13%, 32% 2.5%, 37% 0, 100% 0, 100% 100%, 37% 100%, 32% 97.5%, 31% 87%, 27% 85%, 5.5% 85%, 0 82%);
  }
}
@media (max-width: 1152px) {
  .bgkWrap {
    -webkit-clip-path: polygon(6.8% 18%, 12% 15%, 31.5% 15%, 36.7% 12.5%, 37.5% 2.5%, 42.5% 0, 100% 0%, 100% 100%, 42.5% 100%, 37.5% 97.5%, 36.7% 87%, 31.5% 85%, 12% 85%, 6.8% 82%);
            clip-path: polygon(6.8% 18%, 12% 15%, 31.5% 15%, 36.7% 12.5%, 37.5% 2.5%, 42.5% 0, 100% 0%, 100% 100%, 42.5% 100%, 37.5% 97.5%, 36.7% 87%, 31.5% 85%, 12% 85%, 6.8% 82%);
  }
}
@media (max-width: 1024px) {
  .bgkWrap {
    -webkit-clip-path: polygon(6.8% 18%, 12% 15%, 31.5% 15%, 36.7% 12.5%, 38% 2.5%, 42.5% 0, 100% 0%, 100% 100%, 42.5% 100%, 37.5% 97%, 36.7% 87.5%, 31.5% 85%, 12% 85%, 6.8% 82%);
            clip-path: polygon(6.8% 18%, 12% 15%, 31.5% 15%, 36.7% 12.5%, 38% 2.5%, 42.5% 0, 100% 0%, 100% 100%, 42.5% 100%, 37.5% 97%, 36.7% 87.5%, 31.5% 85%, 12% 85%, 6.8% 82%);
  }
}
@media (max-width: 480px) {
  .bgkWrap {
    -webkit-clip-path: none;
            clip-path: none;
  }
}

.Top {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 263px;
  height: 10%;
}
.Top h2 {
  display: block;
  text-align: left;
  display: block;
  font-size: var(--fontSizeL);
  font-weight: var(--bold);
  font-family: var(--font-headers);
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--colorArticles);
  text-shadow: -4px 4px 2px rgb(0, 0, 0);
}
@media (max-width: 1440px) {
  .Top h2 {
    font-size: 2rem;
  }
}
@media (max-width: 480px) {
  .Top {
    width: 100%;
    right: 50%;
    transform: translate(50%, 0);
  }
}

.warning {
  position: relative;
  display: inline-block;
  width: 80%;
  height: 40px;
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
}

.Middle {
  position: absolute;
  top: calc(15% + 20px);
  right: 0;
  width: 385px;
  height: calc(100% - (40% - 40px));
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 480px) {
  .Middle {
    width: 100%;
    right: 50%;
    transform: translate(50%, 0);
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--colorArticlesA);
}

.lines {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(24, 0, 0, 0) 50%, var(--colorArticlesB) 50%);
  background-size: 100% 4px;
  opacity: 0.8;
}

.scanline {
  width: 100%;
  height: 35%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, var(--colorArticlesA) 10%, rgba(49, 0, 0, 0.1) 100%);
  opacity: 0.55;
  position: absolute;
  bottom: 100%;
  transform: translateY(0);
  will-change: transform;
  animation: scanline 16s linear infinite;
}

.btnWrapper, .btnWrapperB {
  position: absolute;
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
}
@media (max-width: 1366px) {
  .btnWrapper, .btnWrapperB {
    left: -42px;
  }
}
@media (max-width: 1152px) {
  .btnWrapper, .btnWrapperB {
    left: -22px;
  }
}
@media (max-width: 480px) {
  .btnWrapper, .btnWrapperB {
    left: -30px;
  }
}

.btnWrapperB {
  display: none;
}
@media (max-width: 480px) {
  .btnWrapperB {
    display: block;
    left: calc(100vw - 35px);
  }
}

.btnWrapperC {
  z-index: 1;
}

.lineRight {
  position: absolute;
  right: 0;
  top: 0;
  border-right: 2px solid var(--colorArticles);
  height: 100%;
}

.lineLeft {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: calc(100% - (30% + 40px));
  border-right: 2px solid var(--colorArticles);
}
@media (max-width: 1152px) {
  .lineLeft {
    left: 23px;
  }
}
@media (max-width: 480px) {
  .lineLeft {
    left: 0px;
    height: 100%;
  }
}

.lineTop {
  position: absolute;
  border-top: 2px solid var(--colorArticles);
  right: 2px;
  width: 243px;
  top: 0;
}
@media (max-width: 1152px) {
  .lineTop {
    width: 220px;
  }
}
@media (max-width: 480px) {
  .lineTop {
    width: calc(100vw - 4px);
  }
}

.lineBottom {
  position: absolute;
  border-bottom: 2px solid var(--colorArticles);
  right: 2px;
  width: 243px;
  bottom: 0;
}
@media (max-width: 1152px) {
  .lineBottom {
    width: 220px;
  }
}
@media (max-width: 480px) {
  .lineBottom {
    width: calc(100vw - 4px);
  }
}

.line1 {
  position: absolute;
  right: 243px;
  top: 2px;
  transform: skew(135deg);
  height: 20px;
  border-left: 3px solid var(--colorArticles);
  transform-origin: top right;
}
@media (max-width: 1152px) {
  .line1 {
    right: 220px;
  }
}
@media (max-width: 480px) {
  .line1 {
    display: none;
  }
}

.line2 {
  position: absolute;
  right: 263px;
  top: 20px;
  min-height: 20px;
  height: calc(15% - 40px);
  border-left: 2px solid var(--colorArticles);
  transform-origin: top right;
}
@media (max-width: 1152px) {
  .line2 {
    right: 240px;
  }
}
@media (max-width: 480px) {
  .line2 {
    display: none;
  }
}

.line3 {
  position: absolute;
  right: 263px;
  top: calc(15% - 20px);
  transform: skew(135deg);
  height: 20px;
  border-left: 3px solid var(--colorArticles);
  transform-origin: top right;
}
@media (max-width: 1152px) {
  .line3 {
    right: 240px;
  }
}
@media (max-width: 480px) {
  .line3 {
    display: none;
  }
}

.line4 {
  position: absolute;
  right: 284px;
  top: 15%;
  width: 80px;
  border-top: 2px solid var(--colorArticles);
}
@media (max-width: 1152px) {
  .line4 {
    right: 261px;
  }
}
@media (max-width: 480px) {
  .line4 {
    display: none;
  }
}

.line5 {
  position: absolute;
  right: 363px;
  top: 15%;
  transform: skewY(-45deg);
  width: 20px;
  border-top: 2px solid var(--colorArticles);
  transform-origin: top right;
}
@media (max-width: 1152px) {
  .line5 {
    right: 340px;
  }
}
@media (max-width: 480px) {
  .line5 {
    display: none;
  }
}

.line6 {
  position: absolute;
  right: 243px;
  bottom: 2px;
  transform: skew(45deg);
  height: 20px;
  border-left: 3px solid var(--colorArticles);
  transform-origin: bottom left;
}
@media (max-width: 1152px) {
  .line6 {
    right: 220px;
  }
}
@media (max-width: 480px) {
  .line6 {
    display: none;
  }
}

.line7 {
  position: absolute;
  right: 263px;
  bottom: 20px;
  min-height: 20px;
  height: calc(15% - 40px);
  border-left: 2px solid var(--colorArticles);
}
@media (max-width: 1152px) {
  .line7 {
    right: 240px;
  }
}
@media (max-width: 480px) {
  .line7 {
    display: none;
  }
}

.line8 {
  position: absolute;
  right: 263px;
  bottom: calc(15% - 20px);
  transform: skew(45deg);
  height: 20px;
  border-left: 3px solid var(--colorArticles);
  transform-origin: bottom left;
}
@media (max-width: 1152px) {
  .line8 {
    right: 240px;
  }
}
@media (max-width: 480px) {
  .line8 {
    display: none;
  }
}

.line9 {
  position: absolute;
  right: 284px;
  bottom: 15%;
  width: 80px;
  border-top: 2px solid var(--colorArticles);
}
@media (max-width: 1152px) {
  .line9 {
    right: 261px;
  }
}
@media (max-width: 480px) {
  .line9 {
    display: none;
  }
}

.line10 {
  position: absolute;
  right: 363px;
  bottom: 15%;
  transform: skewY(45deg);
  width: 20px;
  border-top: 2px solid var(--colorArticles);
  transform-origin: top right;
}
@media (max-width: 1152px) {
  .line10 {
    right: 340px;
  }
}
@media (max-width: 480px) {
  .line10 {
    display: none;
  }
}/*# sourceMappingURL=ContactBar.module.css.map */