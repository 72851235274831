.buttonicon, .social, .twit, .you, .link, .vim, .git, .plus, .people, .pen, .logout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  width: 67px;
  height: 67px;
  -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
          clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  background-color: var(--colorArticles);
  background-image: url("../../../assets/icons/book.svg");
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 40%;
  margin-bottom: 20px;
  transition: 0.2s ease-in;
}

.buttonicon:hover, .social:hover, .twit:hover, .you:hover, .link:hover, .vim:hover, .git:hover, .plus:hover, .people:hover, .pen:hover, .logout:hover {
  background-color: var(--colorArticles);
}

.logout {
  background-image: url("../../../assets/icons/logout.svg");
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 40%;
}

.pen {
  background-image: url("../../../assets/icons/pen.svg");
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 40%;
}

.people {
  background-image: url("../../../assets/icons/people.svg");
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 60%;
}

.plus {
  background-image: url("../../../assets/icons/plus.svg");
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 40%;
}

.social, .twit, .you, .link, .vim, .git {
  -webkit-clip-path: polygon(10% 0%, 90% 0%, 100% 15%, 100% 85%, 90% 100%, 10% 100%, 0% 85%, 0% 15%);
          clip-path: polygon(10% 0%, 90% 0%, 100% 15%, 100% 85%, 90% 100%, 10% 100%, 0% 85%, 0% 15%);
  background-color: var(--colorBgkVD);
  background-image: none;
  background-repeat: none;
  background-position: none;
  background-size: auto;
  margin: 0;
}
@media (max-width: 480px) {
  .social, .twit, .you, .link, .vim, .git {
    background-color: var(--colorArticles);
  }
}

.git {
  padding: 6px;
  width: 90px;
  height: 45px;
}
@media (max-width: 1600px) {
  .git {
    width: 70px;
    height: 40px;
  }
}
@media (max-width: 1440px) {
  .git {
    width: 65px;
  }
}
@media (max-width: 1366px) {
  .git {
    width: 60px;
  }
}
@media (max-width: 1152px) {
  .git {
    width: 40px;
    padding: 4px;
  }
}
@media (max-width: 480px) {
  .git {
    width: 38px;
    height: 38px;
    padding: 4px;
  }
}

.github {
  width: 100%;
  height: 100%;
  -webkit-mask-image: url("../../../assets/icons/GitHubBig.svg");
          mask-image: url("../../../assets/icons/GitHubBig.svg");
  -webkit-mask-size: 85%;
          mask-size: 85%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: 50%, 60%;
          mask-position: 50%, 60%;
  background-color: white;
  transition: 0.2s ease-in;
}
@media (max-width: 1600px) {
  .github {
    -webkit-mask-size: 95%;
            mask-size: 95%;
  }
}
@media (max-width: 1152px) {
  .github {
    -webkit-mask-image: url("../../../assets/icons/GitHubSmall.svg");
            mask-image: url("../../../assets/icons/GitHubSmall.svg");
    -webkit-mask-size: 100%;
            mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-position: 50%, 50%;
            mask-position: 50%, 50%;
  }
}
@media (max-width: 480px) {
  .github {
    background-color: black;
  }
}

.vim {
  padding: 6px;
  width: 90px;
  height: 45px;
}
@media (max-width: 1600px) {
  .vim {
    width: 70px;
    height: 40px;
  }
}
@media (max-width: 1440px) {
  .vim {
    width: 65px;
  }
}
@media (max-width: 1366px) {
  .vim {
    width: 60px;
  }
}
@media (max-width: 1152px) {
  .vim {
    width: 40px;
    padding: 4px;
  }
}
@media (max-width: 480px) {
  .vim {
    width: 38px;
    height: 38px;
    padding: 4px;
  }
}

.vimeo {
  width: 100%;
  height: 100%;
  -webkit-mask-image: url("../../../assets/icons/VimeoBig.svg");
          mask-image: url("../../../assets/icons/VimeoBig.svg");
  -webkit-mask-size: 85%;
          mask-size: 85%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: 50%, 60%;
          mask-position: 50%, 60%;
  background-color: white;
  transition: 0.2s ease-in;
}
@media (max-width: 1600px) {
  .vimeo {
    -webkit-mask-size: 95%;
            mask-size: 95%;
  }
}
@media (max-width: 1152px) {
  .vimeo {
    -webkit-mask-image: url("../../../assets/icons/VimeoSmall.svg");
            mask-image: url("../../../assets/icons/VimeoSmall.svg");
    -webkit-mask-size: 80%;
            mask-size: 80%;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-position: 50%, 50%;
            mask-position: 50%, 50%;
  }
}
@media (max-width: 480px) {
  .vimeo {
    background-color: black;
  }
}

.link {
  padding: 4px;
  padding-top: 7px;
  padding-left: 5px;
  width: 90px;
  height: 45px;
}
@media (max-width: 1600px) {
  .link {
    width: 70px;
    height: 40px;
  }
}
@media (max-width: 1440px) {
  .link {
    width: 65px;
  }
}
@media (max-width: 1366px) {
  .link {
    width: 60px;
  }
}
@media (max-width: 1152px) {
  .link {
    width: 40px;
    padding: 4px;
  }
}
@media (max-width: 480px) {
  .link {
    width: 38px;
    height: 38px;
    padding: 4px;
  }
}

.linkedin {
  width: 100%;
  height: 100%;
  -webkit-mask-image: url("../../../assets/icons/LinkedinBig.svg");
          mask-image: url("../../../assets/icons/LinkedinBig.svg");
  -webkit-mask-size: 90%;
          mask-size: 90%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: 50%, 50%;
          mask-position: 50%, 50%;
  background-color: white;
  transition: 0.2s ease-in;
}
@media (max-width: 1600px) {
  .linkedin {
    -webkit-mask-size: 100%;
            mask-size: 100%;
  }
}
@media (max-width: 1152px) {
  .linkedin {
    -webkit-mask-image: url("../../../assets/icons/LinkedinSmall.svg");
            mask-image: url("../../../assets/icons/LinkedinSmall.svg");
    -webkit-mask-size: 80%;
            mask-size: 80%;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-position: 50%, 50%;
            mask-position: 50%, 50%;
  }
}
@media (max-width: 480px) {
  .linkedin {
    background-color: black;
  }
}

.you {
  padding: 4px;
  padding-top: 8px;
  padding-left: 5px;
  width: 90px;
  height: 45px;
}
@media (max-width: 1600px) {
  .you {
    width: 70px;
    height: 40px;
  }
}
@media (max-width: 1440px) {
  .you {
    width: 65px;
  }
}
@media (max-width: 1366px) {
  .you {
    width: 60px;
  }
}
@media (max-width: 1152px) {
  .you {
    width: 40px;
    padding: 4px;
  }
}
@media (max-width: 480px) {
  .you {
    width: 38px;
    height: 38px;
    padding: 4px;
  }
}

.youtube {
  width: 100%;
  height: 100%;
  -webkit-mask-image: url("../../../assets/icons/YoutubeBig.svg");
          mask-image: url("../../../assets/icons/YoutubeBig.svg");
  -webkit-mask-size: 90%;
          mask-size: 90%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: 50%, 50%;
          mask-position: 50%, 50%;
  background-color: white;
  transition: 0.2s ease-in;
}
@media (max-width: 1600px) {
  .youtube {
    -webkit-mask-size: 100%;
            mask-size: 100%;
  }
}
@media (max-width: 1152px) {
  .youtube {
    -webkit-mask-image: url("../../../assets/icons/YT.svg");
            mask-image: url("../../../assets/icons/YT.svg");
    -webkit-mask-size: 100%;
            mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-position: 50%, 50%;
            mask-position: 50%, 50%;
  }
}
@media (max-width: 480px) {
  .youtube {
    background-color: black;
  }
}

.twit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 15px;
  width: 110px;
  height: 45px;
}
@media (max-width: 1600px) {
  .twit {
    width: 70px;
    height: 40px;
  }
}
@media (max-width: 1440px) {
  .twit {
    width: 65px;
  }
}
@media (max-width: 1366px) {
  .twit {
    width: 60px;
  }
}
@media (max-width: 1152px) {
  .twit {
    width: 40px;
    padding: 4px;
  }
}
@media (max-width: 480px) {
  .twit {
    width: 38px;
    height: 38px;
    padding: 4px;
  }
}

.twitter {
  width: 100%;
  height: 100%;
  -webkit-mask-image: url("../../../assets/icons/Twitter.svg");
          mask-image: url("../../../assets/icons/Twitter.svg");
  -webkit-mask-size: 75%;
          mask-size: 75%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: 50%, 50%;
          mask-position: 50%, 50%;
  background-color: white;
  transition: 0.2s ease-in;
}
@media (max-width: 1600px) {
  .twitter {
    -webkit-mask-size: 45%;
            mask-size: 45%;
  }
}
@media (max-width: 1440px) {
  .twitter {
    -webkit-mask-size: 70%;
            mask-size: 70%;
  }
}
@media (max-width: 480px) {
  .twitter {
    background-color: black;
    -webkit-mask-size: 75%;
            mask-size: 75%;
  }
}

.buttonicon:hover > .github, .logout:hover > .github, .pen:hover > .github, .people:hover > .github, .plus:hover > .github, .social:hover > .github, .git:hover > .github, .vim:hover > .github, .link:hover > .github, .you:hover > .github, .twit:hover > .github {
  background-color: black;
}
@media (max-width: 480px) {
  .buttonicon:hover > .github, .logout:hover > .github, .pen:hover > .github, .people:hover > .github, .plus:hover > .github, .social:hover > .github, .git:hover > .github, .vim:hover > .github, .link:hover > .github, .you:hover > .github, .twit:hover > .github {
    background-color: white;
  }
}

.buttonicon:hover > .twitter, .logout:hover > .twitter, .pen:hover > .twitter, .people:hover > .twitter, .plus:hover > .twitter, .social:hover > .twitter, .git:hover > .twitter, .vim:hover > .twitter, .link:hover > .twitter, .you:hover > .twitter, .twit:hover > .twitter {
  background-color: black;
}
@media (max-width: 480px) {
  .buttonicon:hover > .twitter, .logout:hover > .twitter, .pen:hover > .twitter, .people:hover > .twitter, .plus:hover > .twitter, .social:hover > .twitter, .git:hover > .twitter, .vim:hover > .twitter, .link:hover > .twitter, .you:hover > .twitter, .twit:hover > .twitter {
    background-color: white;
  }
}

.buttonicon:hover > h3, .logout:hover > h3, .pen:hover > h3, .people:hover > h3, .plus:hover > h3, .social:hover > h3, .git:hover > h3, .vim:hover > h3, .link:hover > h3, .you:hover > h3, .twit:hover > h3 {
  color: black;
}
@media (max-width: 480px) {
  .buttonicon:hover > h3, .logout:hover > h3, .pen:hover > h3, .people:hover > h3, .plus:hover > h3, .social:hover > h3, .git:hover > h3, .vim:hover > h3, .link:hover > h3, .you:hover > h3, .twit:hover > h3 {
    background-color: white;
  }
}

.buttonicon:hover > .vimeo, .logout:hover > .vimeo, .pen:hover > .vimeo, .people:hover > .vimeo, .plus:hover > .vimeo, .social:hover > .vimeo, .git:hover > .vimeo, .vim:hover > .vimeo, .link:hover > .vimeo, .you:hover > .vimeo, .twit:hover > .vimeo {
  background-color: black;
}
@media (max-width: 480px) {
  .buttonicon:hover > .vimeo, .logout:hover > .vimeo, .pen:hover > .vimeo, .people:hover > .vimeo, .plus:hover > .vimeo, .social:hover > .vimeo, .git:hover > .vimeo, .vim:hover > .vimeo, .link:hover > .vimeo, .you:hover > .vimeo, .twit:hover > .vimeo {
    background-color: white;
  }
}

.buttonicon:hover > .youtube, .logout:hover > .youtube, .pen:hover > .youtube, .people:hover > .youtube, .plus:hover > .youtube, .social:hover > .youtube, .git:hover > .youtube, .vim:hover > .youtube, .link:hover > .youtube, .you:hover > .youtube, .twit:hover > .youtube {
  background-color: black;
}
@media (max-width: 480px) {
  .buttonicon:hover > .youtube, .logout:hover > .youtube, .pen:hover > .youtube, .people:hover > .youtube, .plus:hover > .youtube, .social:hover > .youtube, .git:hover > .youtube, .vim:hover > .youtube, .link:hover > .youtube, .you:hover > .youtube, .twit:hover > .youtube {
    background-color: white;
  }
}

.buttonicon:hover > .linkedin, .logout:hover > .linkedin, .pen:hover > .linkedin, .people:hover > .linkedin, .plus:hover > .linkedin, .social:hover > .linkedin, .git:hover > .linkedin, .vim:hover > .linkedin, .link:hover > .linkedin, .you:hover > .linkedin, .twit:hover > .linkedin {
  background-color: black;
}
@media (max-width: 480px) {
  .buttonicon:hover > .linkedin, .logout:hover > .linkedin, .pen:hover > .linkedin, .people:hover > .linkedin, .plus:hover > .linkedin, .social:hover > .linkedin, .git:hover > .linkedin, .vim:hover > .linkedin, .link:hover > .linkedin, .you:hover > .linkedin, .twit:hover > .linkedin {
    background-color: white;
  }
}/*# sourceMappingURL=ButtonIcon.module.css.map */