.customLabel {
  position: absolute;
  top: calc(50% - calc(var(--cardHeight) / 2));
  left: calc(50% - calc(var(--cardWidth) / 2));

  display: block;
  // border: 1px solid greenyellow;

  transition: 400ms ease-in-out;
  cursor: pointer;
  pointer-events: auto;
}
