.bgWrapper {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 70%;
}
@media (max-width: 1366px) {
  .bgWrapper {
    left: 51%;
    width: 89%;
  }
}
@media (max-width: 480px) {
  .bgWrapper {
    height: 85%;
    left: 50%;
    width: 100%;
  }
}

.grid {
  position: absolute;
  left: 40px;
  right: 40px;
  top: 40px;
  bottom: 40px;
  background-image: url(../../../assets/images/siatkaWhite.webp);
  opacity: 0.12;
  background-position: 40px 31px;
  background-color: rgba(255, 255, 255, 0.35);
}
@media (max-width: 480px) {
  .grid {
    background-size: 15%;
    left: 12px;
    right: 12px;
    top: 12px;
    bottom: 12px;
    overflow: hidden;
  }
}

.border {
  position: absolute;
  left: 40px;
  right: 40px;
  top: 40px;
  bottom: 40px;
  border: 1px solid var(--colorArticles);
  border-radius: 7px;
}
@media (max-width: 480px) {
  .border {
    left: 12px;
    right: 12px;
    top: 12px;
    bottom: 12px;
  }
}

.miarkaL {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  background-image: url(../../../assets//images/miarkaWhite.webp);
  background-position: 0px 30px;
}
@media (max-width: 480px) {
  .miarkaL {
    background-position: 0px 12px;
    background-size: 100% 8%;
  }
}

.miarkaR {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
  background-image: url(../../../assets/images/miarkaWhite.webp);
  background-position: -10px 30px;
}
@media (max-width: 480px) {
  .miarkaR {
    right: -5px;
    background-position: 10px 30px;
    background-position: 0px 12px;
    background-size: 100% 8%;
  }
}

.lineL {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 50%;
  border-left: 2px solid var(--black);
}
@media (max-width: 480px) {
  .lineL {
    height: calc(50% + 30px);
    left: 5px;
  }
}

.line1 {
  position: absolute;
  left: 20px;
  top: calc(25% - 20px);
  transform: skew(45deg);
  height: 20px;
  border-left: 2px solid var(--black);
  transform-origin: bottom center;
}
@media (max-width: 480px) {
  .line1 {
    left: 5px;
    height: 5px;
  }
}

.line2 {
  position: absolute;
  left: 0;
  top: 0;
  height: calc(25% - 19px);
  border-left: 2px solid var(--black);
  transform-origin: bottom center;
}

.line3 {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(10% + 24px);
  border-top: 2px solid var(--black);
  transform-origin: center left;
}

.line4 {
  position: absolute;
  left: calc(10% + 24px);
  top: 0;
  width: 20px;
  transform: skewY(45deg);
  border-top: 2px solid var(--black);
  transform-origin: center left;
}
@media (max-width: 480px) {
  .line4 {
    width: 5px;
  }
}

.line5 {
  position: absolute;
  left: calc(10% + 44px);
  top: 19px;
  width: 31%;
  border-top: 2px solid var(--black);
  transform-origin: center left;
}
@media (max-width: 480px) {
  .line5 {
    top: 5px;
    left: calc(10% + 29px);
    width: calc(100% - (20% + 63px));
  }
}

.line6 {
  position: absolute;
  left: calc(41% + 43px);
  top: 20px;
  width: 20px;
  border-top: 2px solid var(--black);
  transform-origin: center left;
  transform: skewY(-45deg);
}
@media (max-width: 480px) {
  .line6 {
    top: 5px;
    width: 5px;
    left: calc(100% - (10% + 34px));
  }
}

.line7 {
  position: absolute;
  left: calc(41% + 62px);
  top: 0px;
  width: calc(59% - 83px);
  border-top: 2px solid var(--black);
  transform-origin: center left;
}
@media (max-width: 480px) {
  .line7 {
    left: calc(100% - (10% + 29px));
    width: calc(10% + 24px);
  }
}

.line8 {
  position: absolute;
  right: 1px;
  top: 0px;
  width: 20px;
  transform: skewY(45deg);
  border-top: 2px solid var(--black);
  transform-origin: center left;
}
@media (max-width: 480px) {
  .line8 {
    top: 0px;
    width: 5px;
    right: 0;
  }
}

.line9 {
  position: absolute;
  right: 0px;
  top: 20px;
  width: 0px;
  height: calc(25% - 39px);
  border-right: 2px solid var(--black);
  transform-origin: center right;
}
@media (max-width: 480px) {
  .line9 {
    top: 5px;
    height: calc(25% - 10px);
    right: 0;
  }
}

.line10 {
  position: absolute;
  right: 0;
  top: calc(25% - 20px);
  transform: skew(135deg);
  height: 20px;
  border-left: 2px solid var(--black);
  transform-origin: top right;
}
@media (max-width: 480px) {
  .line10 {
    top: calc(25% - 5px);
    height: 5px;
    right: 0;
  }
}

.lineR {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 50%;
  border-left: 2px solid var(--black);
}
@media (max-width: 480px) {
  .lineR {
    top: calc(50% + 7px);
    height: calc(50% + 15px);
    right: 5px;
  }
}

.line11 {
  position: absolute;
  right: 20px;
  bottom: calc(25% - 19px);
  height: 20px;
  transform: skew(45deg);
  border-right: 2px solid var(--black);
  transform-origin: top right;
}
@media (max-width: 480px) {
  .line11 {
    right: 5px;
    height: 5px;
  }
}

.line12 {
  position: absolute;
  right: 0;
  bottom: 0;
  height: calc(25% - 19px);
  border-right: 2px solid var(--black);
  transform-origin: bottom center;
}

.line13 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(10% + 24px);
  border-bottom: 2px solid var(--black);
}

.line14 {
  position: absolute;
  bottom: 0;
  right: calc(10% + 24px);
  width: 20px;
  transform: skewY(45deg);
  border-bottom: 2px solid var(--black);
  transform-origin: center right;
}
@media (max-width: 480px) {
  .line14 {
    width: 5px;
  }
}

.line15 {
  position: absolute;
  right: calc(10% + 44px);
  bottom: 20px;
  width: 31%;
  border-bottom: 2px solid var(--black);
  transform-origin: center left;
}
@media (max-width: 480px) {
  .line15 {
    width: calc(100% - (20% + 63px));
    right: calc(10% + 29px);
    bottom: 5px;
  }
}

.line16 {
  position: absolute;
  right: calc(41% + 43px);
  bottom: 20px;
  width: 20px;
  transform: skewY(135deg);
  border-bottom: 2px solid var(--black);
  transform-origin: center right;
}
@media (max-width: 480px) {
  .line16 {
    right: calc(100% - (10% + 34px));
    bottom: 5px;
    width: 5px;
  }
}

.line17 {
  position: absolute;
  right: calc(41% + 63px);
  bottom: 0;
  width: calc(59% - 83px);
  border-bottom: 2px solid var(--black);
  transform-origin: center right;
}
@media (max-width: 480px) {
  .line17 {
    right: calc(100% - (10% + 29px));
    width: calc(10% + 24px);
  }
}

.line18 {
  position: absolute;
  left: 1px;
  bottom: 20px;
  width: 20px;
  transform: skewY(45deg);
  border-bottom: 2px solid var(--black);
  transform-origin: center left;
}
@media (max-width: 480px) {
  .line18 {
    bottom: 5px;
    width: 5px;
  }
}

.line19 {
  position: absolute;
  left: 0;
  bottom: 20px;
  height: calc(25% - 39px);
  border-left: 2px solid var(--black);
  transform-origin: center bottom;
}
@media (max-width: 480px) {
  .line19 {
    height: calc(25% - 25px);
    bottom: 6px;
  }
}

.line20 {
  position: absolute;
  left: 0;
  bottom: calc(25% - 19px);
  height: 20px;
  transform: skew(-45deg);
  border-left: 2px solid var(--black);
  transform-origin: center bottom;
}
@media (max-width: 480px) {
  .line20 {
    height: 5px;
  }
}/*# sourceMappingURL=Background.module.css.map */