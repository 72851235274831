.ldsRing {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 64px;
  height: 64px;
}
.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 54px;
  height: 54px;
  margin: 6px;
  border: 6px solid var(--colorArticles);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--colorArticles) transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
