.wrapperAll, .hidden {
  position: relative;
  width: calc(var(--cardWidth) + 5px);
  height: calc(var(--cardHeight) + 3px);
}

.wrapper, .wrapperColor {
  width: 100%;
  height: calc(100% - 63px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wrapperInner {
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(92% 0%, 100% 8%, 100% 100%, 8% 100%, 0% 92%, 0 0);
          clip-path: polygon(92% 0%, 100% 8%, 100% 100%, 8% 100%, 0% 92%, 0 0);
  position: absolute;
  left: -2px;
  top: 0px;
  transform: scale(96%, 96%);
}

.wrapperColor {
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  align-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  background-color: var(--colorArticlesG);
  box-shadow: none;
  height: 70px;
  border-bottom: 1px dashed var(--black);
  border-top: 1px dashed var(--black);
}
@media (max-width: 1024px) {
  .wrapperColor {
    height: 55px;
  }
}
@media (max-width: 480px) {
  .wrapperColor {
    height: 45px;
  }
}

.btnWrapper {
  width: calc(var(--buttonWidth) / 1.3);
  height: calc(var(--buttonHeight) + 10px);
  position: absolute;
  bottom: 10px;
  left: 10px;
}
@media (max-width: 480px) {
  .btnWrapper {
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(var(--buttonWidth) / 1.3);
    height: calc(var(--buttonHeight) + 8px);
  }
}

.card {
  width: var(--cardWidth);
  min-height: var(--cardHeight);
  filter: drop-shadow(-5px 10px 10px var(--colorArticlesA));
  position: relative;
}

.hidden {
  transform: scale(0.6);
  filter: blur(5px);
  opacity: 0.8;
}

.cardShape, .cardShapeA, .cardShapeB {
  width: var(--cardWidth);
  min-height: var(--cardHeight);
  -webkit-clip-path: polygon(92% 0%, 100% 8%, 100% 100%, 8% 100%, 0% 92%, 0 0);
          clip-path: polygon(92% 0%, 100% 8%, 100% 100%, 8% 100%, 0% 92%, 0 0);
  position: relative;
  background-image: url(../../../assets/backgrounds/simple_dashedC.webp);
  background-repeat: repeat;
}

.cardShapeA, .cardShapeB {
  margin-left: 0px;
  min-height: calc(var(--cardHeight) + 2px);
  position: absolute;
  top: -2px;
  right: -2px;
  border: 20px solid var(--colorArticles);
}

.cardShapeB {
  width: calc(var(--cardWidth) + 4px);
  margin-left: 0px;
  position: absolute;
  bottom: -2px;
  left: -2px;
}

.paragraphWrapper {
  position: relative;
  background-color: var(--colorArticlesDarkB);
  border-top: 3px double var(--colorArticles);
  border-bottom: 3px double var(--colorArticles);
  box-shadow: -4px 4px 8px 0px rgba(73, 152, 255, 0.65);
}
@media (max-width: 480px) {
  .paragraphWrapper {
    border-bottom: 2px solid var(--colorArticles);
    box-shadow: -3px 3px 5px 0px rgba(73, 152, 255, 0.65);
  }
}

.title {
  text-align: left;
  color: var(--white);
  font-size: 2rem;
  padding: 15px;
  padding-bottom: 0;
}
@media (max-width: 1440px) {
  .title {
    font-size: 1.9rem;
  }
}
@media (max-width: 1366px) {
  .title {
    font-size: 1.8rem;
  }
}
@media (max-width: 1024px) {
  .title {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .title {
    font-size: 1.6rem;
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

.code {
  height: 45%;
  width: 25%;
  background-image: url("../../../assets/icons/barcode1.svg");
  background-repeat: no-repeat;
  background-position: 50%, 50%;
}

.decorA {
  width: 200px;
  height: 20px;
  background-color: var(--colorArticlesDarkB);
  position: absolute;
  bottom: -19px;
  right: 0;
  border-bottom: 2px solid var(--colorArticles);
  border-left: 2px solid var(--colorArticles);
  transform: skew(45deg);
  box-shadow: -6px 7px 8px 0px rgba(73, 152, 255, 0.6);
}
@media (max-width: 480px) {
  .decorA {
    bottom: -9px;
    height: 10px;
    box-shadow: -3px 4px 5px 0px rgba(73, 152, 255, 0.6);
  }
}

.decorB {
  width: 20px;
  height: 20px;
  background-color: var(--colorArticlesDarkB);
  position: absolute;
  bottom: -17px;
  right: 0;
}
@media (max-width: 480px) {
  .decorB {
    bottom: -7px;
    width: 10px;
    height: 10px;
  }
}

.decorC {
  width: 0px;
  height: 0px;
  position: absolute;
  bottom: -17px;
  right: 0px;
  border-top: 20px solid var(--colorArticlesDarkB);
  border-right: 21px solid transparent;
}
@media (max-width: 480px) {
  .decorC {
    bottom: -7px;
    border-top: 10px solid var(--colorArticlesDarkB);
    border-right: 11px solid transparent;
  }
}

.decorData {
  position: absolute;
  font-family: var(--font-headers);
  color: var(--white);
  font-weight: 100;
  font-size: 1.4rem;
}
@media (max-width: 1024px) {
  .decorData {
    font-size: 1.2rem;
    bottom: -35px;
    letter-spacing: 1px;
  }
}
@media (max-width: 480px) {
  .decorData {
    font-size: 0.9rem;
    bottom: -22px;
    letter-spacing: 1px;
  }
}/*# sourceMappingURL=Card.module.css.map */