body {
  padding-left: 176px;
  font-family: var(--font-main);
  font-size: 1.6rem;
  overflow: hidden;

  // position: absolute;
  // z-index: -1;
  // height: 100vh;
  width: 100%;
  // background: #3b3a34;
  background-color: black;

  @media (max-width: 1366px) {
    padding-left: 156px;
  }

  @media (max-width: 1024px) {
    padding-left: 126px;
  }

  @media (max-width: 480px) {
    padding: 0;
    // max-width: 100vw;
    // max-height: 100vh;
    height: 100vh;
    width: 100vw;
  }
}

.wrapCont {
  display: block;
  @media (max-width: 480px) {
    pointer-events: none;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    // border: 1px solid red;
  }
}

strong {
  font-weight: 600;
}

h1 {
  font-size: 3.6rem;
}

h2 {
  font-size: 2.8rem;
}

.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centeredB {
  margin: 3rem auto;
  flex-direction: column;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.routeLink {
  display: block;
  text-decoration: none;
}

.page {
  position: relative;
  width: calc(100vw - 176px);
  height: 100vh;
  // background-image: url(../assets/earth.jpg);
  // background-repeat: no-repeat;
  // background-position: 50%, 50%;
  // filter: sepia(0.7);
  // padding: 50px;
  // padding-left: 100px;
  // border: 1px solid greenyellow;

  @media (max-width: 1366px) {
    width: calc(100vw - 156px);
  }

  @media (max-width: 1024px) {
    width: calc(100vw - 126px);
  }

  @media (max-width: 480px) {
    width: 100vw;
  }
}

// .video {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   opacity: 0.75;
// }

.backgroundBody {
  position: absolute;
  width: calc(100vw - 176px);
  height: 100%;
  background: linear-gradient(
    90deg,
    var(--colorArticles) 0%,
    var(--colorBgkL) 2%,
    var(--colorBgkD) 20%,
    var(--colorBgkD) 89%,
    var(--colorBgkL) 96%,
    var(--colorArticles) 100%
  );

  @media (max-width: 1366px) {
    width: calc(100vw - 156px);
  }

  @media (max-width: 1024px) {
    width: calc(100vw - 126px);
  }

  @media (max-width: 480px) {
    width: 100vw;
    background: linear-gradient(
      90deg,
      var(--colorArticles) 0%,
      var(--colorBgkL) 10%,
      var(--colorBgkD) 20%,
      var(--colorBgkD) 80%,
      var(--colorBgkL) 90%,
      var(--colorArticles) 100%
    );
  }
}

.backgroundBack {
  @extend .backgroundBody;
  z-index: -2;
  background: linear-gradient(
    90deg,
    var(--colorArticles) 0%,
    var(--colorBgkL) 2%,
    var(--colorBgkD) 20%,
    var(--colorBgkD) 100%
  );
}

.overlay {
  // border: 1px solid yellow;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--colorArticlesE);

  mix-blend-mode: overlay;
  // backdrop-filter: sephia(50%);
}

.twitter-follow-button {
  font-family: var(--font-headers);
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--white);
  text-decoration: none;
}

.twitter-follow-button:hover {
  color: var(--black);
}

#logo1 {
  fill: red;
}

.wrapperTwit {
  padding-top: 3px;
  height: 28px;
  width: 28px;
}

.headingTwit {
  display: block;
  font-size: 1.2rem;
  transition: 0.2s ease-in;

  @media (max-width: 1600px) {
    display: none;
  }
}

.contactSlide {
  animation-iteration-count: 1;
  animation-duration: 0.6s;
  animation-name: slideKont;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}

.colorIn {
  animation-iteration-count: 1;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
  animation-name: KolorIn;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}

.contactHide {
  animation-iteration-count: 1;
  animation-duration: 0.6s;
  animation-name: hideKont;
  animation-fill-mode: both;
}

.colorOut {
  animation-iteration-count: 1;
  animation-duration: 0.6s;
  animation-name: KolorOut;
  animation-fill-mode: both;
}

input[type='radio'] {
  display: inline-block;
  position: relative;
  width: 22px;
  height: 12px;
  margin: 10px 10px 10px 0;
  opacity: 0.5;
  transition: 120ms transform ease-in-out;
  cursor: pointer;
  pointer-events: auto;
  // display: grid;
  place-content: center;
  @media (max-width: 480px) {
    margin-top: 25px;
    width: 12px;
    height: 12px;
  }
}

input[type='radio']:checked {
  opacity: 1;
}

// input[type='radio']::after {
//   content: '';
//   display: inline-block;
//   background-color: var(--black);
//   width: 18px;
//   height: 8px;
//   // border-radius: 50%;
//   transform: scale(0);
//   transition: 120ms transform ease-in-out;
//   box-shadow: inset 1em 1em var(--black);
// }

// input[type='radio']:checked::after {
//   display: inline-block;
//   transform: scale(1.2);
// }

#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4,
#s5:checked ~ #slide5,
#s6:checked ~ #slide6,
#s7:checked ~ #slide7 {
  // box-shadow: 0 100px 300px var(--colorArticles), 0 50px 100px var(--colorArticles);
  transform: translate3d(0%, 0, 0px);
  @media (max-width: 480px) {
    transform: translate3d(0, 0%, 0px);
  }
}

#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide5,
#s5:checked ~ #slide6,
#s6:checked ~ #slide7,
#s7:checked ~ #slide1 {
  transform: translate3d(30%, 0, -200px);
  opacity: 0.8;
  filter: blur(1px);
  @media (max-width: 480px) {
    transform: translate3d(0, 30%, -200px);
  }
}

#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide5,
#s4:checked ~ #slide6,
#s5:checked ~ #slide7,
#s6:checked ~ #slide1,
#s7:checked ~ #slide2 {
  transform: translate3d(60%, 0, -600px);
  filter: blur(3px);
  opacity: 0.6;
  @media (max-width: 480px) {
    transform: translate3d(0, 60%, -600px);
  }
}

#s1:checked ~ #slide4,
#s2:checked ~ #slide5,
#s3:checked ~ #slide6,
#s4:checked ~ #slide7,
#s5:checked ~ #slide1,
#s6:checked ~ #slide2,
#s7:checked ~ #slide3 {
  transform: translate3d(100%, 0, -1200px);
  filter: blur(5px);
  opacity: 0.4;
  @media (max-width: 480px) {
    transform: translate3d(0, 100%, -1200px);
  }
}

#s1:checked ~ #slide5,
#s2:checked ~ #slide6,
#s3:checked ~ #slide7,
#s4:checked ~ #slide1,
#s5:checked ~ #slide2,
#s6:checked ~ #slide3,
#s7:checked ~ #slide4 {
  transform: translate3d(-100%, 0, -1200px);
  filter: blur(5px);
  opacity: 0.4;
  @media (max-width: 480px) {
    transform: translate3d(0, -100%, -1200px);
  }
}

#s1:checked ~ #slide6,
#s2:checked ~ #slide7,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2,
#s5:checked ~ #slide3,
#s6:checked ~ #slide4,
#s7:checked ~ #slide5 {
  transform: translate3d(-60%, 0, -600px);
  filter: blur(3px);
  opacity: 0.6;
  @media (max-width: 480px) {
    transform: translate3d(0, -60%, -600px);
  }
}

#s1:checked ~ #slide7,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3,
#s5:checked ~ #slide4,
#s6:checked ~ #slide5,
#s7:checked ~ #slide6 {
  transform: translate3d(-30%, 0, -200px);
  filter: blur(1px);
  opacity: 0.8;
  @media (max-width: 480px) {
    transform: translate3d(0, -30%, -200px);
  }
}
// rozmiar odpowiada za slider i powinnien nawiazywac do grid w background
.overflowWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(90% - 80px);
  height: calc(70% - 80px);
  // border: 1px solid red;
  overflow: hidden;
  flex-direction: row;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1366px) {
    left: 51%;
    width: calc(89% - 80px);
  }

  @media (max-width: 480px) {
    height: calc(85% - 24px);
    width: calc(100% - 24px);
    left: 50%;
  }

  // @media (max-width: 1024px) {
  //   width: calc(89% - 110px);
  // }
}
