.radio {
  display: flexbox;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--colorArticles);
  border-radius: 3px;
  border: 1px solid var(--colorGrey100);
  margin: 0;
  transition: 200ms transform ease-in-out;
}

.first {
  border-radius: 8px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.last {
  border-radius: 8px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.radio::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  position: absolute;
  background-color: var(--colorGrey100);
  width: 14px;
  height: 4px;
  border-radius: 2px;
  transform: scale(0);
  transition: 200ms transform ease-in-out;
}
@media (max-width: 480px) {
  .radio::after {
    top: 2px;
    left: 2px;
    width: 6px;
    height: 6px;
  }
}

.radio:checked::after {
  transform: scale(1);
}/*# sourceMappingURL=InputR.module.css.map */