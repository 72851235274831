.paragraph, .details, .article, .card {
  text-align: left;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.1rem;
  color: var(--white);
  padding: 15px;
}

.card {
  position: relative;
}
@media (max-width: 1440px) {
  .card {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}
@media (max-width: 1366px) {
  .card {
    font-size: var(--fontSizeXS);
  }
}
@media (max-width: 1366px) {
  .card {
    font-size: var(--fontSizeXS);
  }
}
@media (max-width: 1024px) {
  .card {
    padding-top: 0px;
  }
}
@media (max-width: 480px) {
  .card {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}

.article {
  display: block;
  width: calc(100% - 40px);
  padding: 30px;
  position: absolute;
  font-size: 1.8rem;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--colorArticlesE);
  border: 1px solid var(--colorArticles);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 500;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}
@media (max-width: 1366px) {
  .article {
    font-size: var(--fontSizeS);
  }
}
@media (max-width: 1024px) {
  .article {
    font-size: var(--fontSizeXS);
    padding: 20px;
  }
}

.details {
  padding-left: 50px;
  padding-right: 345px;
}
@media (max-width: 480px) {
  .details {
    margin: 0;
    padding: 0px;
    font-size: 1.4rem;
  }
}/*# sourceMappingURL=Paragraph.module.css.map */