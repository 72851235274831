.wrapper {
  height: 85px;
  width: 150px;
  // border: 1px solid var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 7px;
  width: 40%;
  height: 55px;
  left: calc(66px + 31% * 1.25);

  @media (max-width: 1280px) {
    row-gap: 50px;
    align-items: flex-start;
  }
}

.name {
  display: block;
  color: var(--white);
  margin: 5px;
  font-size: 1.8rem;
  // border: 1px solid red;
  @media (max-width: 1280px) {
    width: 1.4rem;
  }
}

.email {
  display: block;
  // border: 1px solid yellow;
  color: var(--white);
  margin: 5px;
  font-size: 1.2rem;
}

.picWrapper {
  width: 42px;
  height: 42px;
  margin: 5px;
  // border: 1px solid rgba(255, 255, 255, 0.8);

  img {
    width: 100%;
    height: 100%;
  }
}
