.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0.75;
}
@media (max-width: 480px) {
  .video {
    left: 0;
    opacity: 0.8;
  }
}/*# sourceMappingURL=Video.module.css.map */